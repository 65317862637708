<!-- 预览 -->
<template>
  <el-drawer
    title="报告列表"
    :visible.sync="myOpen"
    size="50%"
    class="elDrawer"
    direction="rtl"
    @close="$emit('input', false)"
  >
    <div class="content_wrap">
      <el-table :data="tableData" class="elTable">
        <el-table-column prop="reportNo" label="报告编号"> </el-table-column>
        <el-table-column prop="finishTime" label="发布时间"> </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handlePreview(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 预览报告 -->
    <el-dialog center :visible.sync="openPreviewReport">
      <apt-pdf :src="previewReportUrl" />
    </el-dialog>
  </el-drawer>
</template>

<script>
import { getReportList } from "@/api/order/bsg/ltc";
import { downloadReport } from "@/api/order/index";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      myData: this.data,
      myOpen: this.value,
      tableData: [], //表格数据
      openPreviewReport: false, //打开预览报告
      previewReportUrl: "", //预览报告地址
    };
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    value(newVal) {
      this.myOpen = newVal;
      if (newVal) this.handleGetOrderLsit();
    },
  },
  computed: {
    status() {
      return this.myData.status;
    },
  },
  methods: {
    // 查找样本列表
    handleGetOrderLsit() {
      getReportList(this.myData.vbillcode).then((data) => {
        this.tableData = data;
      });
    },
    // 预览报告
    handlePreview(row) {
      const params = {
        orderNo: Number(this.myData.vbillcode), //订单编号
        operationType: 1, //操作类型；1：预览。2：下载。
        businessType: this.$utils.map.getUserBstpNo(),
        reportNo: row.reportNo, //报告编号
      };
      downloadReport(params).then((res) => {
        this.$utils.openBlob(res.data);
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elDrawer {
  .content_wrap {
    padding: 10px 22px;
    text-align: left;
    box-sizing: border-box;
    min-height: calc(100% - 81px);
    ::v-deep .elTable {
      width: 100%;
    }
  }
  .elPagination {
    @include end;
    padding: 25px;
    background: #ffffff;
    margin-top: auto;
  }
  ::v-deep .el-drawer__header > :first-child {
    text-align: left;
  }
  .footer {
    display: flex;
    width: 100%;
    padding: 10px 22px 30px 22px;
    border-top: 1px solid $theme_bd_color;
  }
}
</style>
