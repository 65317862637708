<template>
  <div class="operation">
    <span
      class="item"
      @click="handlePreviewReport(viewReportStatus.includes(status))"
    >
      <span :class="{ title: true, active: viewReportStatus.includes(status) }"
        >预览</span
      ></span
    >
    <span
      class="item"
      @click="confirmReport(confirmReportStauts.includes(status))"
    >
      <span
        :class="{ title: true, active: confirmReportStauts.includes(status) }"
        >确认</span
      ></span
    >
    <span
      class="item"
      @click="
        handleDownloadReport(
          downloadReportStauts.includes(status) && myData.canDowload === 'Y'
        )
      "
    >
      <span
        :class="{
          title: true,
          active:
            downloadReportStauts.includes(status) && myData.canDowload === 'Y',
        }"
        >下载</span
      ></span
    >
    <!-- 报告确认单 -->
    <report v-model="openConfirm" v-on="$listeners" :data="myData" />

    <!-- 预览报告 -->
    <preview v-model="openPreview" :data="myData" />

    <!-- 下载报告 -->
    <download v-model="openDownload" :data="myData" />
  </div>
</template>

<script>
import report from "./report.vue";
import preview from "./preview.vue";
import download from "./download.vue";
export default {
  components: {
    report,
    download,
    preview,
  },
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  computed: {
    status() {
      return this.myData.status;
    },
  },
  data() {
    return {
      myData: this.data,
      viewReportStatus: [15, 27, 29, 30], // 可以预览报告的状态
      confirmReportStauts: [15], //可以确认报告的状态
      downloadReportStauts: [27, 29], //可以下载报告的状态
      openConfirm: false, // 确认报告弹窗
      openPreview: false, // 预览报告弹窗
      openDownload: false, // 下载报告弹窗
    };
  },
  methods: {
    // 确认报告弹窗
    confirmReport(active) {
      if (!active) return;
      this.openConfirm = true;
    },
    // 预览报告
    handlePreviewReport(active) {
      if (!active) return;
      this.openPreview = true;
    },
    // 下载报告
    handleDownloadReport(active) {
      if (!active) return;
      this.openDownload = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/bsg/components/result/index.scss";
</style>