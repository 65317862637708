<template>
  <div class="order_wrap elTab">
    <div class="order">
      <el-row class="tab_header">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane :name="route.draft">
              <span slot="label">草稿箱&nbsp;&nbsp;{{ draftTotal }}</span>
            </el-tab-pane>
            <el-tab-pane label="订单列表" :name="route.order"></el-tab-pane>
            <el-tab-pane
              label="2022.7.18前订单"
              :name="route.history"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>

      <div class="queryForm-box">
        <div class="status-wrapper">
          <span class="select-title">状态</span>
          <el-divider direction="vertical"></el-divider>
          <el-select
            v-model="statusValue"
            placeholder="请选择"
            size="medium"
            @change="handleSearchByStatus()"
          >
            <el-option
              v-for="item in statusDict"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
              <span style="float: left">{{ item.title }}</span>
            </el-option>
          </el-select>
        </div>
        <el-input
          placeholder="输入项目名称、样本、批号，回车执行搜索"
          prefix-icon="el-icon-search"
          style="width: 360px; margin-right: 10px"
          size="medium"
          :clearable="true"
          v-model="keywords"
          @clear="reset()"
          @change="changeKeywords"
        ></el-input>
        <el-button @click="reset" size="medium"
          ><svg-icon icon-class="reset"
        /></el-button>
      </div>
      <!-- v-skeleton="{loading:loading, rows:10}" -->
      <div class="table-box">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'background-color': '#fafafa',
            color: '#000000',
            'font-weight': 400,
          }"
          v-loading="loading"
          element-loading-text="数据获取中，请耐心等待..."
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column label="订单信息" min-width="16%">
            <div class="column-box" slot-scope="scope">
              <div class="row-wrap">
                <!-- <span style="margin-right: 10px;">{{ scope.row.sampleNumber}}</span>
								<span>{{ scope.row.orderID }}</span> -->
                <span>{{ scope.row.sampleNumber }}</span>
              </div>
              <div class="row-wrap sub-row">
                <span>{{ scope.row.submitDate }}</span>
              </div>
            </div>
          </el-table-column>
          <el-table-column label="检测信息" min-width="44%">
            <div class="column-box" slot-scope="scope">
              <div class="row-wrap">
                <span>{{ scope.row.testItem }}</span>
              </div>
              <div class="row-wrap sub-row">
                <span>{{ scope.row.sampleName }}</span>
                <span style="margin-left: 10px">{{
                  scope.row.batchNumber
                }}</span>
                <span
                  class="active-grey-wrap"
                  style="margin-left: 10px"
                  @click="handleContractUrl(scope.row.orderID)"
                  >委托协议</span
                >
              </div>
            </div>
          </el-table-column>
          <el-table-column label="检测结果" min-width="20%">
            <div
              class="column-box"
              slot-scope="scope"
              v-if="scope.row.orderStatus === '完成'"
            >
              <div class="row-wrap">
                <span
                  class="active-wrap"
                  @click="handleReportPreview(scope.row.orderID)"
                  v-if="scope.row.reportCheckStatus === 1"
                  >预览</span
                >
              </div>
              <div class="row-wrap btn-wrap">
                <span
                  class="active-wrap"
                  @click="handleReportConfirm(scope.row.orderID)"
                  v-if="scope.row.reportCheckStatus === 1"
                  >报告确认</span
                >
                <span
                  class="active-wrap"
                  @click="handleDownload(scope.row.orderID)"
                  v-if="
                    scope.row.reportDownloadStatus === 1 &&
                    scope.row.reportCheckStatus != 1
                  "
                  >下载</span
                >
              </div>
            </div>
            <div class="column-box" slot-scope="scope" v-else>
              <div class="row-wrap">
                <span class="inactive-wrap">预览</span>
              </div>
              <div class="row-wrap btn-wrap">
                <span class="inactive-wrap">报告确认</span>
              </div>
            </div>
          </el-table-column>
          <el-table-column label="状态描述" min-width="8%">
            <div class="column-box" slot-scope="scope">
              <el-tag
                size="large"
                type="primary"
                plain
                v-if="
                  scope.row.orderStatus === '完成' &&
                  scope.row.reportCheckStatus === 1
                "
                >结果待验收</el-tag
              >
              <!-- <el-tag size="large" type="primary" plain v-else-if="scope.row.orderStatus === '完成'">检测已完成</el-tag> -->
              <el-tag size="large" type="primary" plain v-else>{{
                scope.row.orderStatus
              }}</el-tag>
            </div>
          </el-table-column>
          <el-table-column label="操作" min-width="12%" align="right">
            <div class="column-box" slot-scope="scope">
              <span class="detail-wrap" @click="handleDetail(scope.row.orderID)"
                >查看详情</span
              >
            </div>
          </el-table-column>
          <el-empty slot="empty" description="暂无数据"></el-empty>
        </el-table>
        <div class="page-box" v-if="total > 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <!-- 详情 -->
      <Detail
        :isShowDetailDialog="isShowDetailDialog"
        :orderObj="orderObj"
        @changeDetailDialog="changeDetailDialog"
      />
      <!-- 预览报告 -->
      <el-drawer
        title="预览报告"
        :visible.sync="isShowPreviewDialog"
        size="50%"
        :append-to-body="true"
        @close="isShowPreviewDialog = false"
      >
        <div class="report-wrap" v-html="reportDoc"></div>
      </el-drawer>
      <!-- 选择报告 -->
      <el-dialog
        title="选择报告类型"
        :visible.sync="isShowReportTypeDialog"
        width="30%"
        @close="isShowPreviewDialog = false"
      >
        <el-descriptions
          style="margin: 64px 10%"
          :column="1"
          size="medium"
          border
        >
          <el-descriptions-item>
            <template slot="label"> APT报告 </template>
            <span
              style="color: #409eff; cursor: pointer"
              @click="handleAptReport()"
              >查看</span
            >
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> CMA报告 </template>
            <span
              style="color: #409eff; cursor: pointer"
              @click="handleCmaReport()"
              >查看</span
            >
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
      <!-- 报告确认单 -->
      <el-dialog
        :visible.sync="isShowConfirmDialog"
        width="50%"
        @close="isShowConfirmDialog = false"
      >
        <p style="display: flex; justify-content: center; font-size: 24px">
          报告确认单
        </p>
        <div
          style="
            display: flex;
            flex-direction: column;
            font-size: 18px;
            line-height: 42px;
            margin-top: 36px;
            padding: 0 22px;
          "
        >
          <p style="margin-bottom: 16px">上海中科新生命生物科技有限公司：</p>
          <p style="text-indent: 2em">
            我单位委托贵公司的技术服务（报告编号：<span
              v-for="item in reportNo"
              :key="item.index"
              >{{ item.reportNumber }}，</span
            >）报告电子版已经收悉，并经过我方就报告内容认真审读，核对后作出如下确认：
          </p>
          <p style="text-indent: 2em; font-weight: 600">
            我单位认可贵公司为本次服务编制的报告，并接受本报告电子版内容，现委托贵司收阅本函后尽快出具纸质版报告并交付我方！
          </p>
          <p style="text-indent: 2em">确认方单位名称：{{ customerName }}</p>
          <p style="text-indent: 2em">日期：{{ nowDate }}</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <span style="color: red; margin-right: 24px; text-align: left"
            >提示：如果对报告没有异议，请选择确认；如果有异议，请选择退回</span
          >
          <el-button @click="isShowConfirmDialog = false">取 消</el-button>
          <el-button type="danger" @click="handleReturDialog">退 回</el-button>
          <el-button type="primary" @click="handleSubmitConfirmReport"
            >确 认</el-button
          >
        </span>
      </el-dialog>
      <!-- 退回报告窗口 -->
      <el-dialog
        title="退回说明"
        :visible.sync="isShowReturnDialog"
        width="50%"
        @close="isShowReturnDialog = false"
      >
        <el-form
          :model="form"
          style="margin: 24px 10%"
          label-position="top"
          ref="form"
          @submit.native.prevent
        >
          <el-form-item label="备注" prop="remark" required>
            <el-input
              type="textarea"
              rows="6"
              placeholder="请输入退回原因"
              v-model="form.remark"
              :clearable="true"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isShowReturnDialog = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmitReturnReport"
            >提 交</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="报告下载"
        :visible.sync="isShowDownloadDialog"
        width="30%"
        @close="isShowDownloadDialog = false"
      >
        <el-descriptions
          style="margin: 24px 10% 64px 10%"
          :column="1"
          size="medium"
          border
        >
          <el-descriptions-item>
            <template slot="label"> APT报告 </template>
            <span
              style="color: #409eff; cursor: pointer"
              @click="downloadAptReport()"
              >下载</span
            >
          </el-descriptions-item>
          <el-descriptions-item v-if="cmaReportLink">
            <template slot="label"> CMA报告 </template>
            <span
              style="color: #409eff; cursor: pointer"
              @click="downloadCmaReport()"
              >下载</span
            >
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getHistoryOrder } from "@/api/order/bsg/history";
import { reportsConfirm } from "@/api/order/bsg/history";
import { mixinUser } from "@/mixins/index";
import { mixinOrder } from "@/views/components/order/order/order/index.js";
import Detail from "./detail.vue";
import { Message, Loading } from "element-ui";
export default {
  mixins: [mixinUser, mixinOrder],
  components: {
    Detail,
  },
  data() {
    return {
      active: "/bsg/otd/history", //当前激活的tab路由选项
      route: {
        order: "/bsg/otd/order",
        draft: "/bsg/otd/draft",
        history: "/bsg/otd/history",
      }, //tab路由列表
      // 列表数据
      tableData: [],
      loading: true,
      // 关键词搜索
      keywords: "",
      statusValue: 0,
      statusDict: [
        {
          id: 0,
          title: "全部",
        },
        {
          id: 1,
          title: "待审核",
        },
        {
          id: 2,
          title: "进行中",
        },
        {
          id: 3,
          title: "待验收",
        },
        {
          id: 4,
          title: "已完成",
        },
      ],
      // 分页参数
      total: 0,
      pageNo: 1, //当前页
      pageSize: 10, //每页显示条目数
      // 订单对象
      orderObj: {},
      // 详情弹窗开启状态
      isShowDetailDialog: false,
      // 确认报告窗口
      isShowConfirmDialog: false,
      // 退回报告窗口
      isShowReturnDialog: false,
      // 预览报告窗口
      isShowPreviewDialog: false,
      // 选择报告类型
      isShowReportTypeDialog: false,
      // 报告下载窗口
      isShowDownloadDialog: false,
      // apt报告富文本
      aptReportId: "",
      // cma报告富文本
      cmaReportId: "",
      // 报告文档
      reportDoc: "",
      // 报告编号
      reportNo: [],
      // 客户单位名称
      customerName: "",
      // 退回备注表单
      form: {
        remark: "",
      },
      // 当前日期
      nowDate: "",
      // 订单接口数据
      orderList: [],
      // apt报告链接
      aptReportLink: "",
      // cma报告链接
      cmaReportLink: "",
    };
  },
  created() {
    // this.handleGetData();
    setTimeout(() => {
      this.getData();
    }, 3000);
  },
  computed: {
    AfterChangeData() {
      let start = (this.pageNo - 1) * this.pageSize;
      let end = this.pageNo * this.pageSize;
      return this.tableData.slice(start, end);
    },
  },
  methods: {
    // 点击下载
    handleDownload(e) {
      let arr = this.tableData;
      let reportDownloadArr = [];
      arr.forEach(function (item, index) {
        if (item.orderID === e) {
          reportDownloadArr = item.reportDownloadList;
        }
      });
      if (reportDownloadArr.length > 1) {
        this.aptReportLink = reportDownloadArr[0].downloadUrl;
        this.cmaReportLink = reportDownloadArr[1].downloadUrl;
        this.isShowDownloadDialog = true;
      } else {
        this.aptReportLink = reportDownloadArr[0].downloadUrl;
        this.isShowDownloadDialog = true;
      }
    },
    // 点击下载apt报告
    downloadAptReport() {
      if (this.aptReportLink) {
        window.open(this.aptReportLink, "_blank");
      }
    },
    // 点击下载cma报告
    downloadCmaReport() {
      if (this.cmaReportLink) {
        window.open(this.cmaReportLink, "_blank");
      }
    },
    // 点击退回
    handleReturDialog() {
      this.isShowReturnDialog = true;
      // 避免上次的表单数据未清空
      this.form.remark = "";
    },
    // 点击确认
    handleReportConfirm(e) {
      let arr = this.tableData;
      let reportArr = [];
      let reportNoArr = [];
      arr.forEach(function (item, index) {
        if (item.orderID === e) {
          reportArr = item.reportCheckList;
        }
      });
      if (reportArr.length > 0) {
        reportArr.forEach(function (item) {
          let obj = {
            reportNumber: "",
            reportID: 0,
          };
          obj.reportNumber = item.reportNumber;
          obj.reportID = item.reportID;
          reportNoArr.push(obj);
        });
        this.reportNo = reportNoArr;
        this.customerName = reportArr[0].customerName;
        this.isShowConfirmDialog = true;
      }
      // 处理确认日期
      let d = new Date();
      this.nowDate =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    },
    // 处理预览事件
    handleReportPreview(e) {
      let arr = this.tableData;
      let reportArr = [];
      arr.forEach(function (item, index) {
        if (item.orderID === e) {
          reportArr = item.reportCheckList;
        }
      });
      if (reportArr.length > 1) {
        this.aptReportId = reportArr[0].reportID;
        this.cmaReportId = reportArr[1].reportID;
        this.isShowReportTypeDialog = true;
      } else {
        this.aptReportId = reportArr[0].reportID;
        this.handleAptReport();
      }
    },
    // 查看apt报告
    handleAptReport() {
      let user = this.$utils.storage.get("user");
      let token = user.token;
      if (this.aptReportId) {
        window.open(
          `http://oldbsgorders.aptbiotech.com:8016/#/bsg/otd/preview?reportId=${this.aptReportId}&token=${token}`,
          "_blank"
        );
      }
    },
    // 查看cma报告
    handleCmaReport() {
      let user = this.$utils.storage.get("user");
      let token = user.token;
      if (this.cmaReportId) {
        window.open(
          `http://oldbsgorders.aptbiotech.com:8016/#/bsg/otd/preview?reportId=${this.cmaReportId}&token=${token}`,
          "_blank"
        );
      }
    },
    // 查看委托协议
    handleContractUrl(e) {
      let arr = this.tableData;
      arr.forEach(function (item, index) {
        if (item.orderID === e) {
          console.log(item.contractUrl);
          window.open(item.contractUrl);
        }
      });
    },
    // 状态查询
    handleSearchByStatus() {
      let arr1 = this.orderList;
      let arr2 = [];
      if (this.statusValue == 0) {
        this.reset();
      } else if (this.statusValue == 1) {
        arr1.forEach(function (item, index) {
          if (item.orderStatus === "待审核") {
            arr2.push(item);
          }
        });
      } else if (this.statusValue == 3) {
        arr1.forEach(function (item, index) {
          if (item.orderStatus === "完成" && item.reportCheckStatus === 1) {
            arr2.push(item);
          }
        });
      } else if (this.statusValue == 4) {
        arr1.forEach(function (item, index) {
          if (item.orderStatus === "完成" && item.reportCheckStatus != 1) {
            arr2.push(item);
          }
        });
      } else if (this.statusValue == 2) {
        arr1.forEach(function (item, index) {
          if (item.orderStatus === "待审核") {
          } else if (item.orderStatus === "完成") {
          } else if (item.orderStatus === "填写中") {
          } else {
            arr2.push(item);
          }
        });
      }
      this.tableData = arr2;
      // this.total = this.tableData.length;
    },
    // 加载数据获取
    handleGetData() {
      const {
        bsgOtdHistoryOrder: { status, lastTime },
      } = this.$utils.storage.get("user"); //历史订单数据获取状态
      if (status === 0) {
        // 数据请求中
        const interval = setInterval(function () {
          const orderData = this.$utils.storage.get("bsgOtdHistoryOrder");
          if (orderData) {
            this.getData(orderData);
            clearInterval(interval);
            clearTimeout(timeout);
          }
        }, 1000);
        const timeout = setTimeout(function () {
          clearInterval(interval);
          this.getData();
        }, 60000);
      } else if (status === 1) {
        // 请求成功
        const curTime = new Date().getTime(); // 当前时间
        const validTime = 60 * 60 * 1000; // 数据缓存有效时间，单位毫秒
        if (curTime - lastTime > validTime) {
          console.log("过期");

          //数据缓存过期
          this.getData();
        } else {
          console.log("未过期");

          //数据缓存有效
          const orderData = this.$utils.storage.get("bsgOtdHistoryOrder");
          this.getData(orderData);
        }
      } else if (status === 2) {
        // 请求失败
        this.getData();
      }
    },
    // // 通过接口获取订单数据列表
    // async getData(data) {
    //   try {
    //     let orderData;
    //     if (data) {
    //       orderData = data;
    //     } else {
    //       this.loading = true;
    //       //请求配置
    //       const params = {
    //         config: {
    //           loading: false,
    //           timeout: 60000,
    //         },
    //         params: {
    //           pageNum: this.pageNo,
    //           pageSize: this.pageSize,
    //           query: this.keywords,
    //         },
    //       };
    //       orderData = await getHistoryOrder(config);
    //       //请求数据成功，对数据缓存
    //       this.$utils.storage.set("bsgOtdHistoryOrder", orderData);
    //       const { bsgOtdHistoryOrder } = this.$utils.storage.get("user");
    //       bsgOtdHistoryOrder.status = 1;
    //       bsgOtdHistoryOrder.lastTime = new Date().getTime();
    //       this.$utils.storage.setAttr(
    //         "user",
    //         "bsgOtdHistoryOrder",
    //         bsgOtdHistoryOrder
    //       );
    //     }

    //     // 将数组数据按照倒序排列
    //     // var arr = orderData.allOrderList.reverse()
    //     let arr = orderData.allOrderList;
    //     // 剔除状态为填写中的数据
    //     arr.forEach(function (item, index) {
    //       if (item.orderStatus === "填写中") {
    //         arr.splice(index, 1);
    //       }
    //     });
    //     // 按ID降序排列
    //     arr.sort(this.handleSort("orderID", 0));
    //     // 将数据设置给table
    //     this.tableData = arr;
    //     this.orderList = arr;
    //     this.total = this.tableData.length;
    //     this.loading = false;
    //   } catch (error) {
    //     this.loading = false;
    //   }
    // },
    // 通过接口获取订单数据列表
    async getData() {
      try {
        this.loading = true;
        //请求配置
        const params = {
          config: {
            loading: false,
            timeout: 120000,
          },
          params: {
            pageNum: this.pageNo,
            pageSize: this.pageSize,
            query: this.keywords,
          },
        };
        let orderData = await getHistoryOrder(params);

        // 将数组数据按照倒序排列
        // var arr = orderData.allOrderList.reverse()
        let arr = orderData.allOrderList;
        // 剔除状态为填写中的数据
        arr.forEach(function (item, index) {
          if (item.orderStatus === "填写中") {
            arr.splice(index, 1);
          }
        });
        // 按ID降序排列
        arr.sort(this.handleSort("orderID", 0));
        // 将数据设置给table
        this.tableData = arr;
        this.orderList = arr;
        this.total = orderData.totalNum;
        this.loading = false;
        console.log(this.tableData)
      } catch (error) {
        this.loading = false;
      }
    },
    // 改变搜索值然后搜索
    changeKeywords(){
      this.pageNo = 1;
      this.getData();
    },
    // 排序方法
    handleSort(attr, rev) {
      //第二个参数没有传递 默认升序排列
      if (rev == undefined) {
        rev = 1;
      } else {
        rev = rev ? 1 : -1;
      }
      return function (a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 0;
      };
    },
    // 分页大小改变
    handleSizeChange(val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getData();
    },
    // 当前页数改变
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getData();
    },
    // 关键词搜索
    handleSearch() {
      let kw = this.keywords;
      let table = this.tableData;
      let newArr = [];
      newArr = table.filter((item) => this.isKeyword(item, kw));
      this.tableData = newArr;
      // this.total = newArr.length;
    },
    // 关键词搜索回调方法
    isKeyword(item, kw) {
      return (
        item.sampleNumber.includes(kw) ||
        item.testItem.includes(kw) ||
        item.sampleName.includes(kw) ||
        item.batchNumber.includes(kw)
      );
    },
    // 重置刷新
    reset() {
      this.keywords = "";
      this.statusValue = 0;
      this.getData();
    },
    // 查看详情
    handleDetail(e) {
      let arr = this.tableData;
      let obj = {};
      arr.forEach(function (item) {
        if (item.orderID === e) {
          obj = item;
        }
      });
      this.orderObj = obj;
      console.log(obj);
      if (this.orderObj) {
        this.isShowDetailDialog = true;
      }
    },
    // 改变详情弹窗操作，比如关闭，同步更新父组件于子组件的状态
    changeDetailDialog(val) {
      this.isShowDetailDialog = val;
    },
    // 确认报告提交操作
    handleSubmitConfirmReport() {
      let arr = [];
      let reportNoArr = this.reportNo;
      reportNoArr.forEach(function (item) {
        let obj = {
          reportID: 0,
        };
        obj.reportID = item.reportID;
        arr.push(obj);
      });
      const params = {
        confirmed: 1,
        data: arr,
      };
      // 提交接口，确认报告
      reportsConfirm(params).then(() => {
        // 关闭弹窗
        this.isShowConfirmDialog = false;
        this.reset();
      });
    },
    // 退回报告提交操作
    handleSubmitReturnReport() {
      let arr = [];
      let reportNoArr = this.reportNo;
      let formObj = this.form;
      if (this.form.remark != "") {
        reportNoArr.forEach(function (item) {
          let obj = {
            reportID: 0,
            remark: "",
          };
          obj.remark = formObj.remark;
          obj.reportID = item.reportID;
          arr.push(obj);
        });
        const params = {
          confirmed: 0,
          data: arr,
        };
        console.log(params);
        // 提交接口，退回报告
        reportsConfirm(params).then(() => {
          // 关闭弹窗
          this.isShowConfirmDialog = false;
          this.isShowReturnDialog = false;
          this.reset();
        });
      } else {
        Message({
          type: "error",
          message: "请输入退回原因",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/components/order/order/order/index.scss";

.queryForm-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .status-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 260px;
    height: 36px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    .select-title {
      // margin: auto 15px;
      font-size: 14px;
      color: #909399;
    }
    .el-select {
      width: 160px;
      ::v-deep .el-input__inner {
        height: 34px;
        border: none;
      }
    }
  }
}

.table-box {
  margin-top: 16px;
  margin-bottom: 32px;
  width: 100%;
  min-height: calc(100% - 48px);
}

.el-table {
  .column-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .row-wrap {
      display: flex;
      align-items: center;
      line-height: 28px;
    }
    .sub-row {
      color: #909399;
    }
    .active-grey-wrap {
      cursor: pointer;
      &:hover {
        color: $theme_color;
      }
    }
    .active-wrap {
      cursor: pointer;
      color: $theme_color;
    }
    .inactive-wrap {
      color: #909399;
    }
  }
  .el-tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detail-wrap {
    color: $theme_color;
    cursor: pointer;
  }
}

.page-box {
  width: 100%;
  margin: 32px 0;
  display: flex;
  justify-content: flex-end;
}

.report-wrap {
  margin: 32px 10%;
}
</style>
