<template>
  <!-- 确认BSG-OTD -->
  <el-dialog :visible.sync="open" width="50%" @close="$emit('input', false)">
    <p style="display: flex; justify-content: center; font-size: 24px">
      报告确认单
    </p>
    <div
      style="
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 42px;
        margin-top: 36px;
        padding: 0 22px;
      "
    >
      <p style="margin-bottom: 16px">上海中科新生命生物科技有限公司：</p>
      <p style="text-indent: 2em">
        我单位委托贵公司的技术服务（报告编号：{{
          confirmReportParams.reportNo
        }}）报告电子版已经收悉，并经过我方就报告内容认真审读，核对后作出如下确认：
      </p>
      <p style="text-indent: 2em; font-weight: 600">
        我单位认可贵公司为本次服务编制的报告，并接受本报告电子版内容！
      </p>
      <p style="text-indent: 2em">
        确认方单位名称：{{ confirmReportParams.orgName }}
      </p>
      <p style="text-indent: 2em">
        日期：{{ confirmReportParams.confirmDate }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="open = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmitConfirmReport"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { submitReportConfirmation } from "@/api/order/index";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    value(newVal) {
      this.open = newVal;
    },
    row(newVal) {
      this.myRow = newVal;
    },
  },
  data() {
    return {
      open: this.value, //是否展示弹窗
      confirmReportParams: this.data, //确认报告参数
      myRow: this.row, //一条订单数据
    };
  },
  components: {},
  methods: {
    // 加载提交确认报告
    handleSubmitConfirmReport() {
      this.$messageBox
        .confirm(
          "此操作表示您对报告结果无异议，并提交确认单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          this.submitConfirmReport();
        })
        .catch(() => {});
    },
    // 提交确认报告
    submitConfirmReport() {
      const params = {
        orderNo: this.confirmReportParams.orderNo,
        businessType: this.$utils.map.getUserBstpNo(),
      };
      submitReportConfirmation(params).then((res) => {
        this.open = false;
        this.$message({
          type: "success",
          message: "确认报告提交成功",
        });
        this.$emit("handleGetOrderLsit");
        //触发评价弹窗
        this.$emit("orderEvent", {
          data: [this.myRow],
          type: "bsgOtdSubmitAppraise",
        });
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped></style>
