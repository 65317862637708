<template>
  <!-- 加急详情 -->
  <el-dialog
    title="加急审核详情"
    :visible.sync="open"
    :append-to-body="true"
    :show-close="true"
    width="40%"
    @close="$emit('input', false)"
  >
    <div class="content-box">
      <div class="step-box">
        <el-steps :active="detail.active" align-center>
          <el-step title="已提交" :description="detail.committime"></el-step>
          <el-step title="审核中" description=""></el-step>
          <el-step
            title="已完成"
            :description="detail.active === 3 ? detail.approvetime : ''"
          ></el-step>
        </el-steps>
      </div>
      <div class="remark-box">
        <el-descriptions class="margin-top" title="申请说明" :column="1" border>
          <el-descriptions-item>
            <template slot="label"> 备注 </template>
            {{ detail.jjsqbz | showDefaultOfNull }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        class="remark-box"
        v-if="detail.urgentstatus == '2' || detail.urgentstatus == '3'"
      >
        <el-descriptions class="margin-top" title="审核结果" :column="1" border>
          <el-descriptions-item>
            <template slot="label"> 状态 </template>
            {{ remarkStatus }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注 </template>
            {{ detail.jjspbz | showDefaultOfNull }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="open = false" type="primary">知道了</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { urgentDetail } from "@/api/order/bsg/otd.js";
import { order } from "@/utils/dict.js";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    value(newVal) {
      this.open = newVal;
      if (newVal) this.getOrderDetail();
    },
  },
  data() {
    return {
      open: this.value, //是否展示弹窗
      myData: this.data, // 接收父组件参数
      detail: {
        active: 1, //	审核详情步骤激活参数，取1或3
        urgentstatus: "", //加急审批是否通过，3-不通过，2-通过
      }, // 查询回来的订单审核详情数据
    };
  },
  computed: {
    // 审批状态描述
    remarkStatus() {
      if (this.detail.urgentstatus == "3") {
        const { status } = order;
        for (const item of status) {
          if (item.value == this.detail.status) {
            return item.name;
          }
        }
        return "未通过";
      } else if (this.detail.urgentstatus == "2") {
        return "已通过";
      }
    },
  },
  methods: {
    //获取订单加急详情
    getOrderDetail() {
      urgentDetail(this.myData.vbillcode).then((res) => {
        this.detail = Object.assign({}, this.detail, res);
        if (
          this.detail.urgentstatus == "2" ||
          this.detail.urgentstatus == "3"
        ) {
          // 订单已完成
          this.detail.active = 3;
        } else {
          // 订单未完成
          this.detail.active = 1;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-box {
  margin: 24px;
  display: flex;
  flex-direction: column;
  .remark-box {
    margin-top: 48px;
  }
}
</style>
