<template>
  <div class="column">
    <!-- <div class="row">
      <span class="mr black lh">{{ myData.productname }}</span>
    </div> -->
    <div class="row">
      <span class="gray">{{ myData.ismilestone }}</span>
    </div>
    <div class="row">
      <!-- <span class="gray cs hr" @click="$emit('handleDetail', myData)"
        >样本信息</span
      >
      <el-divider direction="vertical"></el-divider> -->
      <span class="gray">{{
        getNewTitle(`合同号${myData.pk_busicontract}`)
      }}</span>
      <!-- <el-divider direction="vertical"></el-divider>
      <span class="gray">
        {{ getNewTitle(`项目号${myData.cprojectid}`) }}
      </span> -->
    </div>
  </div>
</template>

<script>
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      busiType: order.busiType,
    };
  },
  components: {},
  methods: {
    // 获取新的字段名
    getNewTitle(title) {
      return title;
    },
  },
};
</script>
<style lang="scss" scoped></style>
