<template>
  <!-- 确认BSG-LTC -->
  <el-dialog
    :visible.sync="open"
    width="50%"
    class="bsgLtc"
    @close="$emit('input', false)"
  >
    <p style="display: flex; justify-content: center; font-size: 24px">
      提交确认单
    </p>
    <div
      style="
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 42px;
        margin-top: 36px;
        padding: 0 22px;
      "
    >
      <p>上海中科新生命生物科技有限公司：</p>
      <p style="text-indent: 2em">
        我单位委托贵公司的技术服务（合同号：<span
          style="border-bottom: 1px solid black; line-height: 2"
          >{{ confirmReportParams.contractNo }}</span
        >，方案编号：<span
          style="border-bottom: 1px solid black; line-height: 2"
          >{{ confirmReportParams.planNo }}</span
        >，订单编号：<span
          style="border-bottom: 1px solid black; line-height: 2"
          >{{ confirmReportParams.orderNo }}</span
        >，报告编号：<span
          style="border-bottom: 1px solid black; line-height: 2"
          >{{ confirmReportParams.reportNo }}</span
        >）报告电子版已经收悉，并经过我方就报告内容认真审读、核对后作出如下确认：
      </p>
      <p style="text-indent: 2em">
        <span
          style="
            border-bottom: 1px solid black;
            line-height: 2;
            font-weight: bold;
          "
          >我单位认可贵公司为本次服务编制的报告，并接受本报告电子版内容！</span
        >
      </p>
      <p style="margin-left: 2em; margin-top: 10em">
        确认方单位名称：{{ confirmReportParams.orgName }}
      </p>
      <p style="margin-left: 2em">
        日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：{{
          confirmReportParams.confirmDate
        }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="open = false">取 消</el-button>
      <el-button type="primary" @click="submitBsgLtcConfirmReport"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { submitReportConfirmation, getConfirmReport } from "@/api/order/index";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    value(newVal) {
      this.open = newVal;
      if (newVal) {
        this.handleGetConfirmReport();
      }
    },
  },
  data() {
    return {
      open: this.value, //是否展示弹窗
      myData: this.data, //订单数据
      confirmReportParams: {
        confirmDate: "",
        orgName: "",
        reportNo: "",
        orderNo: undefined,
        contractNo: "", //合同号
        contractName: "", //联系人名称
        planNo: "", //方案号
      }, //确认报告参数
    };
  },
  components: {},
  methods: {
    // 提交BSG-LTC报告确认
    submitBsgLtcConfirmReport() {
      this.$messageBox
        .confirm(
          "此操作表示您对报告结果无异议，并提交确认单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          const params = {
            orderNo: Number(this.myData.vbillcode),
            businessType: this.$utils.map.getUserBstpNo(),
          };
          submitReportConfirmation(params).then((res) => {
            this.open = false;
            this.$message({
              type: "success",
              message: "确认报告提交成功",
            });
            this.$emit("handleGetOrderLsit");
            this.$emit("refreshOrderStatusCount");
            //触发评价弹窗
            this.$emit("orderEvent", {
              data: [this.myData],
              type: "bsgLtcSubmitAppraise",
            });
          });
        })
        .catch(() => {});
    },
    // 获取报告参数
    handleGetConfirmReport() {
      const params = {
        orderNo: Number(this.myData.vbillcode),
        businessType: this.$utils.map.getUserBstpNo(),
      };
      getConfirmReport(params).then((res) => {
        this.confirmReportParams.confirmDate = res.confirmDate;
        this.confirmReportParams.orgName = res.orgName;
        this.confirmReportParams.reportNo = res.reportNo;
        this.confirmReportParams.orderNo = Number(this.myData.vbillcode);
        this.confirmReportParams.contractNo = res.contractNo;
        this.confirmReportParams.contractName = res.contractName;
        this.confirmReportParams.planNo = res.planNo;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
