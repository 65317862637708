<template>
  <div class="index">
    <apt-page-tab v-model="active"></apt-page-tab>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      active: "/bsg/ltc/order", //当前激活页
    };
  },
};
</script>

<style lang="scss" scoped>
.index {
  min-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
