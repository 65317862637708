<!-- 申请打印 -->
<template>
  <el-dialog
    title="申请打印"
    :visible.sync="show"
    width="600px"
    @close="show = false"
    align="left"
    class="applyPrint"
  >
    <div class="dialog-content">
      <p class="desc">请填写报告寄送地址信息</p>
      <el-form
        :model="form"
        :rules="rules"
        label-position="top"
        ref="form"
        @submit.native.prevent
      >
        <el-form-item label="收件人" prop="name">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="form.name"
            clearable
            maxlength="100"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="form.mobile"
            clearable
            maxlength="20"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="form.address"
            clearable
            maxlength="200"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getReportAddresse, applyPrint } from "@/api/order/bsg/ltc";
import utils from "@/utils/index.js";

//校验电话正确性
const isRightMobile = (rule, value, cb) => {
  if (
    value &&
    (utils.validate.isTel(value) || utils.validate.isMobile(value))
  ) {
    cb();
  } else {
    cb(new Error("请填写正确的格式"));
  }
};
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    cancel() {
      this.show = false;
      this.resetForm();
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) return;
      const params = {
        ...this.form,
        orderNo: this.myData.vbillcode,
      };
      applyPrint(params).then((data) => {
        this.$message({
          type: "success",
          message: "申请打印报告已成功",
        });
        this.$emit("handleGetOrderLsit");
        this.$emit("refreshOrderStatusCount");
        this.cancel();
      });
    },
    //获取用户历史数据
    getUserHistoryData() {
      getReportAddresse().then((res) => {
        this.form.name = res.contactPerson;
        this.form.mobile = res.telephone;
        this.form.address = res.address;
      });
    },
  },
  data() {
    return {
      myData: this.data,
      form: {
        name: "",
        mobile: "",
        address: "",
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入", trigger: "blur" },
          { required: true, trigger: "blur", validator: isRightMobile },
        ],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.value) {
          this.getUserHistoryData();
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.applyPrint ::v-deep {
  .dialog-content{
    .desc{
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 22px;
    }
  }
}
</style>
