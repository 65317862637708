<template>
  <div class="operation">
    <div class="row">
      <span
        class="item"
        v-if="logisticsStatus.includes(status)"
        @click="viewLogistics"
      >
        <span class="title">查看物流</span></span
      >
      <span
        class="item"
        v-if="auditRemarkStatus.includes(status)"
        @click="auditRemarkDialog = true"
      >
        <span class="title">审核详情</span></span
      >
      <span
        class="item"
        v-if="urgentRemarkStatus.includes(status)"
        @click="urgentRemarkDialog = true"
      >
        <span class="title">加急详情</span></span
      >
      <span
        class="item"
        v-if="afterSaleRemarkStatus.includes(status)"
        @click="afterSaleRemarkDialog = true"
      >
        <span class="title">售后详情</span></span
      >
      <span
        class="item"
        v-if="resultConfirmed.includes(status)"
        @click="dialogApplyAfterSale = true"
      >
        <span class="title">申请售后</span></span
      >
      <span
        class="item"
        v-if="rejectSubmit.includes(status)"
        @click="handeRejectSubmit"
      >
        <span class="title">重新提交</span></span
      >
      <span
        class="item"
        v-if="rejectSubmit.includes(status)"
        @click="handleDelete"
      >
        <span class="title">取消订单</span></span
      >
      <span
        class="item"
        v-if="applyPrintStatus.includes(status) && myData.iskhsqdybg !== 'Y'"
        @click="handleApplyPrint(myData)"
      >
        <span class="title">申请打印</span></span
      >
    </div>
    <div class="row">
      <span class="item" @click="viewDetail(myData)">
        <span class="title">订单详情</span></span
      >
      <span class="item" @click="copy(myData)">
        <span class="title">复制下单</span></span
      >
      <span
        class="item"
        @click="handleAppraise('bsgOtd')"
        v-if="showAppraiseStatus.includes(myData.status)"
      >
        <span class="title">{{ appraiseBtn }}</span></span
      >
    </div>
    <!-- 申请售后 -->
    <el-dialog
      title="申请售后"
      :visible.sync="dialogApplyAfterSale"
      :append-to-body="true"
      :show-close="true"
      width="40%"
    >
      <el-form
        :rules="applyAfterSaleRule"
        :model="applyAfterSaleForm"
        class="applyAfterSaleForm"
        ref="applyAfterSale"
        label-position="top"
      >
        <div>
          <el-form-item label="申请说明：" prop="remark">
            <el-input
              type="textarea"
              v-model="applyAfterSaleForm.remark"
              :rows="5"
              minlength="5"
              maxlength="300"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="上传图片（单个文件不能超过10MB）："
            prop="uploadImg"
            style="margin-top: 24px"
          >
            <el-upload
              list-type="picture-card"
              :on-success="
                (response, file, fileList) => updateFile(file, fileList)
              "
              :on-remove="(file, fileList) => updateFile(file, fileList)"
              class="TheortSeqImg"
              multiple
              :limit="5"
              :headers="uploadHeaders"
              :data="myUpload.data"
              :action="myUpload.action"
            >
              <i class="el-icon-plus add"></i>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogApplyAfterSale = false">取消</el-button>
        <el-button @click="handleApplyAfterSale" type="primary">提交</el-button>
      </div>
    </el-dialog>
    <!-- 审核详情 -->
    <review v-model="auditRemarkDialog" :data="myData" />
    <!-- 加急审核详情 -->
    <urgent v-model="urgentRemarkDialog" :data="myData" />
    <!-- 售后审核详情 -->
    <after-sale v-model="afterSaleRemarkDialog" :data="myData" />

    <!-- 提交订单弹窗 -->
    <apt-create-order
      v-model="showCreateOrder"
      :data="rejectSubmitQuery"
      title="提交"
      @success="rejectSubmitSuccess"
    />
    <!-- 查看备注 -->
    <el-drawer
      title="订单流转-备注信息"
      :visible.sync="dialogRemark"
      direction="rtl"
      size="75%"
      class="elDrawer"
      append-to-body
    >
      <operation-remark :data="remarkData" />
    </el-drawer>
    <!-- 订单详情 -->
    <detail v-model="openBsgOtd" ref="bsgOtd" />
  </div>
</template>

<script>
import detail from "./detail.vue";
import operation from "@/views/bsg/components/operation/index.js";
import { getLogistics } from "@/api/order/index";
import { applyPrint } from "@/api/order/bsg/otd";
export default {
  mixins: [operation],
  components: {
    detail,
  },
  data() {
    return {
      logisticsStatus: [12, 29], //	查看物流对应的状态列表
      applyPrintStatus: [27, 29], //可以申请打印的状态列表
      showAppraiseStatus: [27, 29, 33], //可以查看评价、服务评价的状态列表
    };
  },
  methods: {
    // 查看物流
    viewLogistics() {
      const params = {
        id: this.myData.vbillcode,
        params: {
          type: "",
        },
      };
      if (this.status === 12) {
        //送样物流
        params.params.type = 4;
      } else if (this.status === 29) {
        //报告物流
        params.params.type = 5;
      }
      getLogistics(params).then((data) => {
        if (data.url) {
          window.open(data.url, "_blank");
        } else {
          this.$message({
            type: "warning",
            message: "暂无物流信息，请稍后再试",
          });
        }
      });
    },
    // 查看订单详情
    async viewDetail(val) {
      const { vbillcode } = val;
      await this.$refs.bsgOtd.handleGetOrderDetail(vbillcode);
      this.openBsgOtd = true;
    },
    // 申请打印
    handleApplyPrint({ vbillcode }) {
      this.$messageBox
        .confirm("确认是否申请打印报告?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            orderNo: vbillcode,
          };
          applyPrint(params).then((data) => {
            this.$message({
              type: "success",
              message: "申请打印报告已成功",
            });
            this.$emit("handleGetOrderLsit");
            this.$emit("refreshOrderStatusCount");
          });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消申请打印报告",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/bsg/components/operation/index.scss";
</style>
