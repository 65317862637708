<template>
  <div class="operation">
    <span
      class="item"
      @click="handlePreviewReport(viewReportStatus.includes(status))"
    >
      <span :class="{ title: true, active: viewReportStatus.includes(status) }"
        >预览</span
      ></span
    >
    <span
      class="item"
      @click="confirmReport(confirmReportStauts.includes(status))"
    >
      <span
        :class="{ title: true, active: confirmReportStauts.includes(status) }"
        >确认</span
      ></span
    >
    <span
      class="item"
      @click="handleDownloadReport(downloadReportStauts.includes(status))"
    >
      <span
        :class="{
          title: true,
          active: downloadReportStauts.includes(status),
        }"
        >下载</span
      ></span
    >
    <!-- 预览报告 -->
    <el-dialog center :visible.sync="dialogViewReport">
      <apt-pdf :src="pdfUrl" />
    </el-dialog>

    <!-- 选择报告 -->
    <select-report v-model="openSelect" @view="previewReport" :data="myData"/>

    <!-- 报告确认单 -->
    <report
      v-model="openConfirm"
      :data="confirmReportParams"
      :row="myData"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { getConfirmReport } from "@/api/order/index";
import report from "./report.vue";
import { saveAs } from "file-saver";
import { downloadReport } from "@/api/order/index";
import selectReport from "@/views/bsg/components/selectReport.vue";
export default {
  components: {
    report,
    selectReport,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    //上传图片参数
    upload: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      dialogViewReport: false, //查看报告弹窗
      pdfUrl: "", //pdf地址
      viewReportStatus: [15, 27, 28, 29], // 可以预览报告的状态
      confirmReportStauts: [15, 28], //可以确认报告的状态
      downloadReportStauts: [27, 29], //可以下载报告的状态
      openConfirm: false, // 确认报告弹窗
      openSelect: false, // 报告存在两份是需要弹窗展示
      confirmReportParams: {
        confirmDate: "",
        orgName: "",
        reportNo: "",
        orderNo: undefined,
      }, //确认报告参数
    };
  },
  computed: {
    status() {
      return this.myData.status;
    },
    myUpload() {
      return this.upload;
    },
    canDowload() {
      return (
        this.downloadReportStauts.includes(this.myData.status) &&
        this.myData.candowload === "Y"
      );
    },
  },
  methods: {
    // 确认报告弹窗
    async confirmReport(active) {
      if (!active) return;
      this.openConfirm = true;
      const params = {
        orderNo: Number(this.myData.vbillcode),
        businessType: this.$utils.map.getUserBstpNo(),
      };
      getConfirmReport(params).then((res) => {
        this.confirmReportParams.confirmDate = res.confirmDate;
        this.confirmReportParams.orgName = res.orgName;
        this.confirmReportParams.reportNo = res.reportNo;
        this.confirmReportParams.orderNo = Number(this.myData.vbillcode);
      });
    },
    // 下载报告
    handleDownloadReport(active) {
      if (!active) return;
      this.$messageBox
        .confirm("此操作将下载报告, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const report = this.myData.report.split("+");
          if (report.includes("A")) {
            this.downloadReport("A");
          }
          if (report.includes("B")) {
            this.downloadReport("B");
          }
          if (report.includes("C")) {
            this.downloadReport("C");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消下载",
          });
        });
    },
    // 下载报告
    downloadReport(type) {
      const params = {
        orderNo: Number(this.myData.vbillcode),
        operationType: 2, //操作类型；1：预览。2：下载。
        businessType: this.$utils.map.getUserBstpNo(),
        type,
      };
      downloadReport(params).then((res) => {
        const { data, fileName } = res;
        const blob = new Blob([data], {
          type: "application/pdf;chartset=UTF-8",
        });
        const fileName2 = fileName.split(".")[0];
        saveAs(blob, fileName2);
        this.$message({
          type: "success",
          message: "下载报告成功",
        });
      });
    },
    // 加载预览报告
    handlePreviewReport(active) {
      if (!active) return;
      const report = this.getReport();
      if (report.length === 1) {
        // 报告数量一份，直接打开报告
        this.previewReport({
          type: report[0],
        });
      } else if (report.length > 1) {
        // 报告数量大于1份，弹窗给用户选择
        this.openSelect = true;
      } else {
        // 没有报告报错给用户
        this.$message({
          type: "error",
          message: "该笔订单没有报告",
        });
      }
    },
    // 获取报告数据
    getReport() {
      const report = this.myData.report;
      if (report) {
        return report.split("+");
      }
      return [];
    },
    // 预览报告
    previewReport(reportParams) {
      const { type } = reportParams;
      const params = {
        orderNo: Number(this.myData.vbillcode), //订单编号
        operationType: 1, //操作类型；1：预览。2：下载。
        businessType: this.$utils.map.getUserBstpNo(),
        type: type ? type : "",
      };
      downloadReport(params).then((res) => {
        this.$utils.openBlob(res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/bsg/components/result/index.scss";
</style>
