var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"mr black lh"},[_vm._v(_vm._s(_vm.myData.productname))])]),_c('div',{staticClass:"row"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.myData.samplename,"placement":"right"}},[_c('span',[_vm._v(_vm._s(_vm.samplename))])]),(_vm.getProtocalName(_vm.myData))?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_c('span',{staticClass:"gray cs mr hr",on:{"click":function($event){return _vm.entrustProtocol(_vm.myData)}}},[_vm._v(_vm._s(_vm.getProtocalName(_vm.myData)))]),(
        _vm.myData.sfjj === 'Y' &&
        [12, 13, 14, 15, 16, 17, 25, 27, 28, 29].includes(_vm.myData.status)
      )?_c('svg-icon',{staticClass:"urgent mr",attrs:{"icon-class":"urgentSuccess"}}):_vm._e(),(
        _vm.myData.sfjj === 'Y' &&
        [11, 20, 21, 23, 24, 26].includes(_vm.myData.status)
      )?_c('svg-icon',{staticClass:"urgent mr",attrs:{"icon-class":"urgentReady"}}):_vm._e(),(_vm.myData.lotno)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.myData.lotno,"placement":"right"}},[_c('span',[_vm._v(_vm._s(_vm.lotno))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }