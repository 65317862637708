<template>
  <div class="container">
    <div class="report" v-html="html"></div>
  </div>
</template>

<script>
import { getReport } from "@/api/order/bsg/otd";
export default {
  data() {
    return {
      html: undefined, //
    };
  },
  methods: {
    // 预览报告
    previewReport() {
      const { reportId, token } = this.$route.query;
      this.$utils.storage.set("user", {
        token,
      });
      const params = {
        reportId, //订单编号
        token,
      };
      getReport(params).then((data) => {
        this.html = data.reportHTML;
      });
    },
  },
  created() {
    this.previewReport();
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .report{
    margin: 0 auto;
  }
}
</style>
