<template>
  <draft v-model="active" :route="route" />
</template>

<script>
import draft from "@/views/components/order/draft/draft.vue";
export default {
  components: {
    draft,
  },
  data() {
    return {
      active: "/bsg/ltc/draft", //当前激活页
      route: {
        order: "/bsg/ltc/order",
        draft: "/bsg/ltc/draft",
      },
    };
  },
};
</script>
