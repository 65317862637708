<template>
  <div class="order_wrap elTab">
    <div class="order">
      <el-row class="tab_header">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane :name="route.draft">
              <span slot="label">草稿箱&nbsp;&nbsp;{{ draftTotal }}</span>
            </el-tab-pane>
            <el-tab-pane label="订单列表" :name="route.order"></el-tab-pane>
            <el-tab-pane
              label="2022.7.18前订单"
              :name="route.history"
              v-if="route.history"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="operation">
        <div class="btn">
          <el-button
            type="primary"
            @click="openCreateOrder"
            size="medium"
            v-if="showCreateOrderBtn"
            >新建订单</el-button
          >
          <el-button
            type="primary"
            @click="batchAppraise"
            size="medium"
            v-if="form.state == '33'"
            >批量提交评价</el-button
          >
          <!-- <el-button plain @click="openBatchCreateOrder" size="medium"
            >批量新建</el-button
          > -->
          <!-- <el-button  plain @click.native="copy" size="medium">复制新建</el-button> -->
          <!-- <el-button type="primary" plain>导出</el-button> -->
          <el-button
            type="primary"
            plain
            @click="batchConfirm"
            v-if="form.state == '15,28'"
            size="medium"
            >批量确认报告</el-button
          >
          <el-button
            type="primary"
            plain
            @click="batchDownload"
            v-if="form.state == '32'"
            size="medium"
            >批量下载报告</el-button
          >
          <el-button
            type="primary"
            plain
            @click="batchApplyPrint"
            v-if="form.state == '31'"
            size="medium"
            >批量申请打印报告</el-button
          >
        </div>
        <query-form
          :data="form"
          :tableData="tableData"
          @search="search"
          ref="queryForm"
        />
      </div>

      <!-- 列表 -->
      <bsg-table
        :form="form"
        :data="tableData"
        :upload="upload"
        @handleGetOrderLsit="refreshOrder"
        @refreshOrderStatusCount="refreshOrderStatusCount"
        @select="select"
        @orderEvent="orderEvent"
        ref="orderTable"
        v-bind="$attrs"
      />
    </div>

    <!-- 分页 -->
    <div class="elPagination">
      <el-pagination
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="form.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改默认密码-->
    <modify-default-password :open="open" />

    <!-- 下单 -->
    <apt-create-order
      v-model="showCreateOrder"
      @success="submitOrderSuccess"
      title="新建订单"
      :data="query"
    />

    <!-- 批量下单 -->
    <apt-batch-create-order v-model="showBatchCreateOrder" />

    <!-- 服务评价 -->
    <appraise
      v-model="showAppraise"
      :data="appraiseData"
      @success="appraiseSuccess"
    />
  </div>
</template>

<script>
import bsgTable from "./bsgTable.vue";
import queryForm from "@/views/components/order/order/queryForm.vue";
import appraise from "@/views/bsg/components/appraise.vue";
import {
  getBsgOtdOrderList,
  submitReportConfirmation,
  batchApplyPrint,
} from "@/api/order/index";
import { applyPrint } from "@/api/order/bsg/otd";
import { batchDownload as batchDownloadApi } from "@/api/order/bsg/otd";
import modifyDefaultPassword from "@/views/home/home/modifyDefaultPassword.vue";
import { mixinUser } from "@/mixins/index";
import { mixinOrder } from "@/views/components/order/order/order/index.js";
import { mixinCheckOrder } from "@/views/bsg/components/checkOrder/index.js";
import { getContractInfo } from "@/api/order/bsg/otd";
export default {
  mixins: [mixinUser, mixinOrder, mixinCheckOrder],
  components: {
    bsgTable,
    queryForm,
    modifyDefaultPassword,
    appraise,
  },
  data() {
    return {
      active: "/bsg/otd/order", //当前激活的tab路由选项
      route: {
        order: "/bsg/otd/order",
        draft: "/bsg/otd/draft",
        history: "",
      }, //tab路由列表
      query: {
        type: "createOrder",
      },
    };
  },
  methods: {
    //获取草稿订单列表
    async handleGetOrderLsit() {
      const params = {
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
        bpOrderNo: this.form.bpOrderNo, //订单编号
        productName: this.form.productName, //产品名称
        sampleName: this.form.sampleName, //样品名称
        lotNo: this.form.lotNo, //样品批号
        // [this.form.selectValue]: this.form.searchValue, //订单编号
        // startTime: this.$utils.map.parseTime(this.form.date[0], "{y}-{m}-{d}"),
        // endTime: this.$utils.map.parseTime(this.form.date[1], "{y}-{m}-{d}"),
        status: this.form.state == "-1" ? "" : this.form.state,
        type: 21, //BSG-OTD type为21
      };
      const params2 = this.$utils.map.getNotEmptyObj(params);
      const orderData = await getBsgOtdOrderList(params2);
      //获取table数据
      this.tableData = orderData.orderList;
      this.total = orderData.totalNum;
    },
    async openCreateOrder() {
      getContractInfo().then((data) => {
        this.query = Object.assign({}, this.query, data);
      });
      this.showCreateOrder = true;
    },
    //更新订单状态数量
    refreshOrderStatusCount() {
      this.$refs.queryForm.handleGetOrderStatusCount();
    },
    //批量确认报告
    batchConfirm() {
      if (!this.selected.length) {
        this.$message({
          type: "warning",
          message: "请至少选择一个订单",
        });
        return;
      }
      this.$messageBox
        .confirm(
          `本次批量确认报告提交${this.selected.length}个订单, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          const params = {
            orderNo: this.selected.map((item) => item.vbillcode).join(","),
            businessType: this.$utils.map.getUserBstpNo(),
          };
          submitReportConfirmation(params).then((res) => {
            this.$message({
              type: "success",
              message: "批量确认报告提交成功",
            });
            this.handleGetOrderLsit();
            this.refreshOrderStatusCount();
            this.orderEvent({
              type: "bsgOtdSubmitAppraise",
              data: this.selected,
            });
          });
        })
        .catch(() => {});
    },
    //批量下载
    batchDownload() {
      if (!this.selected.length) {
        this.$message({
          type: "warning",
          message: "请至少选择一个订单",
        });
        return;
      }
      this.$messageBox
        .confirm(
          `本次批量下载报告提交${this.selected.length}个订单, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          const params = {
            orderNos: this.selected.map((item) => item.vbillcode).join(","),
          };
          batchDownloadApi(params).then((res) => {
            const { data, fileName } = res;
            const blob = new Blob([data], {
              type: "application/zip;chartset=UTF-8",
            });
            const fileName2 = fileName.split(".")[0];
            saveAs(blob, fileName2);
            this.$message({
              type: "success",
              message: "批量下载报告提交成功",
            });
          });
        })
        .catch(() => {});
    },
    //批量申请打印报告
    batchApplyPrint() {
      if (!this.selected.length) {
        this.$message({
          type: "warning",
          message: "请至少选择一个订单",
        });
        return;
      }
      this.$messageBox
        .confirm(
          `本次批量申请打印报告提交${this.selected.length}个订单, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          const params = {
            orderNo: this.selected.map((item) => item.vbillcode).join(","),
          };
          applyPrint(params).then((res) => {
            this.$message({
              type: "success",
              message: "批量申请打印报告提交成功",
            });
            this.handleGetOrderLsit();
            this.refreshOrderStatusCount();
          });
        })
        .catch(() => {});
    },
    // 批量提交评价
    batchAppraise() {
      //先校验多选数据是否可评价
      if (!this.selected.length) {
        this.$message({
          type: "warning",
          message: "请先选择至少一笔订单",
        });
        return;
      }
      this.appraiseData = {
        data: this.selected,
        type: "bsgOtdBatchSubmitAppraise", //评价类型：查看
      };
      this.showAppraise = true;
    },
    // 评价成功
    appraiseSuccess() {
      this.refreshOrder();
      this.refreshOrderStatusCount();
    },
  },
  created() {
    this.handleCheckOrder();
    this.handleGetOrderLsit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/components/order/order/order/index.scss";
</style>
