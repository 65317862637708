<template>
  <div>
    <router-view></router-view>
    <ad />
  </div>
</template>

<script>
import ad from "./ad.vue";
export default {
  components: {
    ad,
  },
};
</script>
