<template>
  <div class="operationRemark">
    <div class="operationDetail_wrap">
      <el-timeline class="elTimeLine">
        <el-timeline-item
          v-for="(item, index) of operationDetail"
          :key="index"
          :timestamp="$utils.map.parseTime(item.createTime)"
          placement="top"
          color="#1890ff"
        >
          <div class="operationDetail">
            <div class="type pd">
              <span>操作类型：</span>
              <span v-if="item.operationType === 1">订单审核</span>
              <span v-else-if="item.operationType === 2">售后申请</span>
              <span v-else-if="item.operationType === 3">售后受理</span>
              <span v-else-if="item.operationType === 4">物流信息</span>
              <template
                v-if="
                  item.operationType === 1 ||
                  item.operationType === 2 ||
                  item.operationType === 3
                "
              >
                <span style="margin-left: 30px">审核意见：</span>
                <span style="color: green">{{
                  item.auditStatus | fmtADS
                }}</span>
              </template>
            </div>
            <div class="info pd" v-if="item.operationType === 4">
              <span>快递信息：</span>
              <span>{{ item.trackingNumber }}</span>
            </div>
            <div class="remark pd">
              <span>操作备注：</span>
              <span>{{ item.remark }}</span>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { getOperationRemark } from "@/api/order/index";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    data: {
      handler(newVal) {
        this.myData = newVal;
        this.getOperationType();
        this.handleGetOperationRemark();
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      myData: this.data,
      operationDetail: [], // 操作详细数据
      operationType: "", // 操作类型
      reviewRemarkStatus: [1], //包含审核备注的状态
      aftersalesRemarkStatus: [15], //包含售后备注的状态
      viewLogisticsStatus: [12, 19], //包含查看物流的状态
    };
  },
  filters: {
    fmtADS(val) {
      if (val === 1) {
        return "同意";
      } else if (val === 2) {
        return "驳回";
      }
    },
  },
  methods: {
    // 获取备注内容
    handleGetOperationRemark() {
      getOperationRemark(this.myData.vbillcode).then((res) => {
        this.operationDetail = res;
      });
    },
    // 获取操作类型
    getOperationType() {
      const status = this.myData.status;
      if (this.reviewRemarkStatus.includes(status)) {
        this.operationType = "审核备注";
      } else if (this.aftersalesRemarkStatus.includes(status)) {
        this.operationType = "售后备注";
      } else if (this.viewLogisticsStatus.includes(status)) {
        this.operationType = "查看物流";
      }
    },
  },
  created() {
    this.getOperationType();
  },
};
</script>

<style lang="scss" scoped>
.operationRemark {
  .operationDetail_wrap {
    padding: 0 20px;
    & ::v-deep .elTimeLine {
      .operationDetail {
        .pd {
          padding: 12px 0;
        }
      }
    }
  }
}
</style>
