/**
 * @desc BSG-LTC接口
 */

import request from "@/utils/request";

// 获取报告列表
export function getReportList(id) {
  return request({
    url: `/apt-css/order/bsg/ltc/listReportNo/${id}`,
    method: "get",
  });
}

// 获取物流信息
export function getLogistics(params) {
  return request({
    url: `/apt-css/order/bsg/ltc/get/logistics/${params.id}`,
    method: "get",
    params: params.params,
  });
}

//申请打印报告
export function applyPrint(data) {
  return request({
    url: `/apt-css/order/bsg/ltc/applyPrintReport`,
    method: "post",
    data,
  });
}

//提交订单评分
export function rateOrder(data) {
  return request({
    url: `/apt-css/order/bsg/ltc/rateOrder`,
    method: "post",
    data,
  });
}

//查看订单评分
export function queryRate(params) {
  return request({
    url: "/apt-css/order/bsg/ltc/queryRate",
    method: "get",
    params,
  });
}

//获取报告地址
export function getReportAddresse() {
  return request({
    url: "/apt-user/user/address/reportAddress",
    method: "get",
  });
}
