<template>
  <div class="order_wrap elTab">
    <div class="order">
      <el-row class="tab_header">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="handleClick">
            <!-- <el-tab-pane :name="route.draft">
              <span slot="label">草稿箱&nbsp;&nbsp;{{ draftTotal }}</span>
            </el-tab-pane> -->
            <el-tab-pane label="订单列表" :name="route.order"></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="operation">
        <div class="btn">
          <!-- <el-button
            type="primary"
            @click="openCreateOrder"
            size="medium"
            v-if="showCreateOrderBtn"
            >新建订单</el-button
          > -->
          <el-button
            type="primary"
            @click="batchAppraise"
            size="medium"
            v-if="form.state == '33'"
            >批量提交评价</el-button
          >
          <!-- <el-button plain @click="openBatchCreateOrder" size="medium"
            >批量新建</el-button
          > -->
          <!-- <el-button  plain @click.native="copy" size="medium">复制新建</el-button> -->
          <!-- <el-button type="primary" plain>批量确认报告</el-button>
					<el-button type="primary" plain>批量下载报告</el-button>
					<el-button type="primary" plain>导出</el-button> -->
        </div>
        <query-form
          :data="form"
          :tableData="tableData"
          @search="search"
          ref="queryForm"
        />
      </div>

      <!-- 列表 -->
      <bsg-table
        :data="tableData"
        :upload="upload"
        @handleGetOrderLsit="handleGetOrderLsit"
        @refreshOrderStatusCount="refreshOrderStatusCount"
        @select="select"
        @orderEvent="orderEvent"
        ref="orderTable"
        v-bind="$attrs"
      />
    </div>

    <!-- 分页 -->
    <div class="elPagination">
      <el-pagination
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="form.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改默认密码 -->
    <modify-default-password :open="open" />

    <!-- 下单 -->
    <apt-create-order
      v-model="showCreateOrder"
      @success="submitOrderSuccess"
      title="新建订单"
    />

    <!-- 批量下单 -->
    <apt-batch-create-order v-model="showBatchCreateOrder" />

    <!-- 服务评价 -->
    <appraise
      v-model="showAppraise"
      :data="appraiseData"
      @success="appraiseSuccess"
    />
  </div>
</template>

<script>
import bsgTable from "./bsgTable.vue";
import queryForm from "@/views/components/order/order/queryForm.vue";
import appraise from "@/views/bsg/components/appraise.vue";
import { getBsgLtcOrderList } from "@/api/order/index";
import modifyDefaultPassword from "@/views/home/home/modifyDefaultPassword.vue";
import { mixinUser } from "@/mixins/index";
import { mixinOrder } from "@/views/components/order/order/order/index.js";
export default {
  mixins: [mixinUser, mixinOrder],
  components: {
    bsgTable,
    queryForm,
    modifyDefaultPassword,
    appraise,
  },
  data() {
    return {
      active: "/bsg/ltc/order", //当前激活的tab路由选项
      route: {
        order: "/bsg/ltc/order",
        draft: "/bsg/ltc/draft",
      }, //tab路由列表
    };
  },
  methods: {
    //获取草稿订单列表
    async handleGetOrderLsit() {
      const params = {
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
        bpOrderNo: this.form.bpOrderNo,
        productName: this.form.productName,
        sampleName: this.form.sampleName,
				contractNo: this.form.contractNo,
        // [this.form.selectValue]: this.form.searchValue, //订单编号
        // startTime: this.$utils.map.parseTime(this.form.date[0], "{y}-{m}-{d}"),
        // endTime: this.$utils.map.parseTime(this.form.date[1], "{y}-{m}-{d}"),
        status: this.form.state == -1 ? "" : this.form.state,
        type: 22,
      };
      const params2 = this.$utils.map.getNotEmptyObj(params);
      const orderData = await getBsgLtcOrderList(params2);
      //获取table数据
      this.tableData = JSON.parse(JSON.stringify(orderData.orderList));
      this.total = orderData.totalNum;
    },
    setBusType() {
      this.form.businessType = 22;
    },
    refreshOrderStatusCount() {
      this.$refs.queryForm.handleGetOrderStatusCount();
    },
    // 批量提交评价
    batchAppraise() {
      //先校验多选数据是否可评价
      if (!this.selected.length) {
        this.$message({
          type: "warning",
          message: "请先选择至少一笔订单",
        });
        return;
      }
      this.appraiseData = {
        data: this.selected,
        type: "bsgLtcBatchSubmitAppraise", //评价类型：查看
      };
      this.showAppraise = true;
    },
    // 评价成功
    appraiseSuccess() {
      this.refreshOrder();
      this.refreshOrderStatusCount();
    },
  },
  created() {
    this.handleGetOrderLsit();
    this.setBusType();
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/components/order/order/order/index.scss";
</style>
