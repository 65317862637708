<template>
  <div class="orderTable">
    <el-table
      :data="myData"
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#fafafa',
        color: '#000000',
        'font-weight': 400,
      }"
			stripe
      class="elTable"
      ref="elTable"
      @selection-change="selectChange"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="showSelection.includes(myForm.state)"
      ></el-table-column>
      <el-table-column label="订单信息" align="left" min-width="18%">
        <div slot-scope="scope" class="column">
          <div class="row">
            <span class="mr black">{{ scope.row.vbillcode }}</span>
            <el-tooltip
              class="item"
              effect="light"
              content="点击复制订单号"
              placement="right"
            >
              <i
                class="el-icon-document-copy cs copy black"
                :data-clipboard-text="scope.row.vbillcode"
              ></i>
            </el-tooltip>
          </div>
          <div class="row">
            <el-tooltip
              class="item"
              effect="light"
              content="订单创建时间"
              placement="right"
            >
              <span class="mr gray">{{
                $utils.map.parseTime(scope.row.dbilldate)
              }}</span>
              <!-- <i class="el-icon-stopwatch gray"></i> -->
            </el-tooltip>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="检测信息" align="left" min-width="32%">
        <div slot-scope="scope" class="column">
          <test :data="scope.row" @handleDetail="handleDetail" />
        </div>
      </el-table-column>
      <el-table-column label="检测结果" min-width="10%">
        <template slot-scope="scope">
          <result :data="scope.row" v-on="$listeners" />
        </template>
      </el-table-column>
      <el-table-column label="状态描述" min-width="20%">
        <template slot-scope="scope">
          <span class="status" :style="getStatusColor(scope.row.status)">{{
            getStatusName(scope.row.status)
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="operationRemark" label="操作备注">
        <template slot-scope="scope">
          <operation-remark :data="scope.row" />
        </template>
      </el-table-column> -->
      <el-table-column
        prop="operation"
        label="操作"
        align="right"
        min-width="20%"
      >
        <template slot-scope="scope">
          <operation
            :data="scope.row"
            :selected="selected"
            v-bind="$attrs"
            v-on="$listeners"
            ref="operation"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import result from "@/views/bsg/otd/order/result.vue";
import test from "@/views/bsg/otd/order/test.vue";
import bsgTable from "@/views/bsg/components/bsgTable/index.js";
import operation from "./operation.vue";
export default {
  mixins: [bsgTable],
  components: {
    result,
    test,
    operation,
  },
  data() {
    return {
      showSelection: ["15,28", "31", "32", "33"], //是否展示多选框
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/bsg/components/bsgTable/index.scss";
</style>
