var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"operation"},[_c('span',{staticClass:"item",on:{"click":function($event){_vm.handlePreviewReport(_vm.viewReportStatus.includes(_vm.status))}}},[_c('span',{class:{ title: true, active: _vm.viewReportStatus.includes(_vm.status) }},[_vm._v("预览")])]),_c('span',{staticClass:"item",on:{"click":function($event){_vm.confirmReport(_vm.confirmReportStauts.includes(_vm.status))}}},[_c('span',{class:{ title: true, active: _vm.confirmReportStauts.includes(_vm.status) }},[_vm._v("确认")])]),_c('span',{staticClass:"item",on:{"click":function($event){_vm.handleDownloadReport(
        _vm.downloadReportStauts.includes(_vm.status) && _vm.myData.canDowload === 'Y'
      )}}},[_c('span',{class:{
        title: true,
        active:
          _vm.downloadReportStauts.includes(_vm.status) && _vm.myData.canDowload === 'Y',
      }},[_vm._v("下载")])]),_c('report',_vm._g({attrs:{"data":_vm.myData},model:{value:(_vm.openConfirm),callback:function ($$v) {_vm.openConfirm=$$v},expression:"openConfirm"}},_vm.$listeners)),_c('preview',{attrs:{"data":_vm.myData},model:{value:(_vm.openPreview),callback:function ($$v) {_vm.openPreview=$$v},expression:"openPreview"}}),_c('download',{attrs:{"data":_vm.myData},model:{value:(_vm.openDownload),callback:function ($$v) {_vm.openDownload=$$v},expression:"openDownload"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }