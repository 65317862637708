<template>
  <!-- 选择报告 -->
  <el-dialog
    title="选择报告"
    :visible.sync="open"
    :append-to-body="true"
    :show-close="true"
    width="30%"
    @close="$emit('input', false)"
  >
    <div class="selectReport_wrap">
      <el-descriptions class="margin-top" title="" :column="1" border>
        <el-descriptions-item v-if="reportTypes.includes('B')">
          <template slot="label"> APT报告 </template>
          <span class="view_btn" @click="viewApt">查看</span>
        </el-descriptions-item>
        <el-descriptions-item v-if="reportTypes.includes('A')">
          <template slot="label"> CMA报告 </template>
          <span class="view_btn" @click="viewCma">查看</span>
        </el-descriptions-item>
        <el-descriptions-item v-if="reportTypes.includes('C')">
          <template slot="label"> CNAS报告 </template>
          <span class="view_btn" @click="viewCnas">查看</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="open = false" type="primary">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      open: this.value, //打开弹窗
      myData: this.data,
    };
  },
  watch: {
    value(newVal) {
      this.open = newVal;
    },
    data(newVal) {
      this.myData = newVal;
    },
  },
  computed: {
    reportTypes() {
      return this.myData.report?.split("+") ?? [];
    },
  },
  components: {},
  methods: {
    viewApt() {
      this.$emit("view", {
        type: "B",
      });
    },
    viewCma() {
      this.$emit("view", {
        type: "A",
      });
    },
    viewCnas() {
      this.$emit("view", {
        type: "C",
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.selectReport_wrap {
  .view_btn {
    margin-right: 100px;
    cursor: pointer;
  }
}
</style>
