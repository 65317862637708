import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    form(newVal){
      this.myForm = newVal;
    }
  },
  data() {
    return {
      myData: this.data,
      myForm: this.form,
      selected: [], //当前选中项
      resultType: undefined, //展示的result组件类型
    };
  },
  methods: {
    // 加载详情
    handleDetail(row) {
      this.$refs.operation.viewDetail(row);
    },
    selectChange(val) {
      this.selected = val;
      this.$emit("select", val);
    },
    // 获取状态name
    getStatusName(value) {
      const { status } = order;
      let name;
      status.forEach((item) => {
        if (item.value === value) {
          name = item.name;
          return;
        }
      });
      return name;
    },
    // 获取订单状态颜色
    getStatusColor(val) {
      const selected = order.status.find((item) => item.value === val);
      if (selected && selected.color) {
        return `color: ${selected.color}; border: 1px solid ${selected.bdColor}; background: ${selected.bgColor};`;
      }
    },
  },
};
