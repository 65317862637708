<template>
	<el-drawer title="查看详情" :direction="direction" :visible.sync="isShow" size="70%" :append-to-body="true"
		@close="handleClose">
		<div class="content">
			<el-descriptions class="margin-top" title="检测信息" direction="vertical" :column="3" :size="size" border>
				<el-descriptions-item>
					<template slot="label">
						检测项目
					</template>
					{{ orderObj.testItem }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						分子量
					</template>
					{{ orderObj.molecularWeight }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						N端氨基酸测序的残基数目
					</template>
					{{ orderObj.ncount }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						缓冲体系
					</template>
					{{ orderObj.bufferSystem }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						理论等电点
					</template>
					{{ orderObj.theoreticalIsoelectricPoint ? orderObj.theoreticalIsoelectricPoint : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						测定范围
					</template>
					{{ orderObj.measurementRange ? orderObj.measurementRange : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						理论序列
					</template>
					{{ orderObj.theoreticalSequence }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						物种信息
					</template>
					{{ orderObj.speciesInformation ? orderObj.speciesInformation : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						理论站点
					</template>
					{{ orderObj.theoreticalSites ? orderObj.theoreticalSites : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						检测依据
					</template>
					{{ orderObj.relationStandards }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						鉴定时长
					</template>
					{{ orderObj.appraisalTimes ? orderObj.appraisalTimes : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						其他装饰类型
					</template>
					{{ orderObj.otherDecorateType ? orderObj.otherDecorateType : '/' }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						报告版本
					</template>
					{{ orderObj.reportMode }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						报告语言
					</template>
					{{ orderObj.reportLanguage }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						检测类别
					</template>
					{{ orderObj.testType }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						送样方式
					</template>
					{{ orderObj.samplingMethod }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						余样处理
					</template>
					{{ orderObj.residualTreatment }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						是否留样
					</template>
					{{ orderObj.leaveSample }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						其他检测要求
					</template>
					{{ orderObj.otherTestRequire ? orderObj.otherTestRequire : '/' }}
				</el-descriptions-item>
			</el-descriptions>
			
			<el-descriptions class="margin-top sample-box" title="样本信息" direction="vertical" :column="3" :size="size" border>
				<el-descriptions-item>
					<template slot="label">
						样品类型
					</template>
					{{ orderObj.sampleType }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品来源
					</template>
					{{ orderObj.sampleSource }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品名称
					</template>
					{{ orderObj.sampleName }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品批号
					</template>
					{{ orderObj.batchNumber }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品数量
					</template>
					{{ orderObj.sampleQty }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						纯度
					</template>
					{{ orderObj.purity }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						有效期
					</template>
					{{ orderObj.expiryDate }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品规格
					</template>
					{{ orderObj.specification }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						保存条件
					</template>
					{{ orderObj.storageRequire }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品包装
					</template>
					{{ orderObj.samplePacking }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品状态
					</template>
					{{ orderObj.sampleStatus }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						样品性状
					</template>
					{{ orderObj.sampleColor }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						浓度
					</template>
					{{ orderObj.sampleConcentration }}{{ orderObj.sampleConcentrationUnit }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						体积
					</template>
					{{ orderObj.sampleVolume }}{{ orderObj.sampleVolumeUnit }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						重量
					</template>
					{{ orderObj.sampleWeight }}{{ orderObj.sampleWeightUnit }}
				</el-descriptions-item>
			</el-descriptions>
		</div>
	</el-drawer>
</template>

<script>
	export default {
		name: 'detail',
		components: {
			
		},
		// 从父组件传值到子组件
		props: {
			orderObj: {
				type: Object,
				default: () => {}
			},
			isShowDetailDialog: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				direction: 'rtl',
				size: 'medium',
			}
		},
		computed: {
			isShow: {
				get() {
					return this.isShowDetailDialog
				},
				set(val) {
					this.$emit('changeDetailDialog', val);
				}
			}
		},
		watch: {
			// 对应props接收orderObj对象，监听对象变化，更新页面数据
			orderObj(newValue, oldValue) {
				this.orderObj = newValue;
			}
		},
		methods: {
			// 右上角关闭操作
			handleClose() {
				this.$emit('changeDetailDialog', false);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		margin: 24px 10% 32px 10%;
		.warp {
			color: #409EFF;
			cursor: pointer;
		}
		.sample-box {
			margin-top: 36px;
		}
	}
	
</style>