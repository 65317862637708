<!-- 下载 -->
<template>
  <el-drawer
    title="报告列表"
    :visible.sync="myOpen"
    size="50%"
    class="elDrawer"
    direction="rtl"
    @close="$emit('input', false)"
  >
    <div class="content_wrap">
      <el-table :data="tableData" class="elTable">
        <el-table-column prop="reportNo" label="报告编号"> </el-table-column>
        <el-table-column prop="finishTime" label="发布时间"> </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleDownloadReport(scope.row)"
              v-if="downloadReportStauts.includes(status)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
import { getReportList } from "@/api/order/bsg/ltc";
import { downloadReport } from "@/api/order/index";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      myData: this.data,
      myOpen: this.value,
      tableData: [], //表格数据
      downloadReportStauts: [27, 29], //可以下载报告的状态
    };
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    value(newVal) {
      this.myOpen = newVal;
      if (newVal) this.handleGetOrderLsit();
    },
  },
  computed: {
    status() {
      return this.myData.status;
    },
  },
  methods: {
    // 查找样本列表
    handleGetOrderLsit() {
      getReportList(this.myData.vbillcode).then((data) => {
        this.tableData = data;
      });
    },
    // 下载报告
    handleDownloadReport(row) {
      this.$messageBox
        .confirm("此操作将下载报告, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.downloadReport(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消下载",
          });
        });
    },
    // 下载报告
    downloadReport(row) {
      const params = {
        orderNo: Number(this.myData.vbillcode),
        operationType: 2, //操作类型；1：预览。2：下载。
        businessType: this.$utils.map.getUserBstpNo(),
        reportNo: row.reportNo, //报告编号
      };
      downloadReport(params).then((res) => {
        const { data, fileName } = res;
        const blob = new Blob([data], {
          type: "application/pdf;chartset=UTF-8",
        });
        const fileName2 = fileName.split(".")[0];
        saveAs(blob, fileName2);
        this.$message({
          type: "success",
          message: "下载报告成功",
        });
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elDrawer {
  .content_wrap {
    padding: 10px 22px;
    text-align: left;
    box-sizing: border-box;
    min-height: calc(100% - 81px);
    ::v-deep .elTable {
      width: 100%;
    }
  }
  .elPagination {
    @include end;
    padding: 25px;
    background: #ffffff;
    margin-top: auto;
  }
  ::v-deep .el-drawer__header > :first-child {
    text-align: left;
  }
  .footer {
    display: flex;
    width: 100%;
    padding: 10px 22px 30px 22px;
    border-top: 1px solid $theme_bd_color;
  }
}
</style>
