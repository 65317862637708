<!-- 广告 -->
<template>
  <el-dialog
    :visible.sync="show"
    width="60%"
    center
    class="ad"
    append-to-body
    :show-close="false"
    :modal="true"
    :close-on-click-modal="false"
  >
    <img class="img" src="~@/assets/images/ad.png" alt="" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" type="text" class="btn">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  methods: {
    //获取广告弹窗
    getAd() {
      const adWindow = this.$utils.storage.get("user").adWindow;
      if (adWindow) {
        this.show = true;
      }
    },
    //关闭弹窗
    close() {
      this.show = false;
      this.$utils.storage.setAttr("user", "adWindow", false);
    },
  },
  data() {
    return {
      show: false, //弹窗展示
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.getAd();
  },
};
</script>
<style lang="scss" scoped>
.ad ::v-deep {
  .el-dialog {
    .img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 8px 0;
      .btn {
        @include center;
        width: 100%;
        height: 100%;
        font-size: 16px;
      }
    }
  }
}
</style>
