<template>
  <div class="column">
    <div class="row">
      <span class="mr black lh">{{ myData.productname }}</span>
    </div>
    <div class="row">
      <!-- <span class="gray">{{ myData.samplename }}</span> -->
      <el-tooltip
        class="item"
        effect="light"
        :content="myData.samplename"
        placement="right"
      >
        <span>{{ samplename }}</span>
      </el-tooltip>
      <el-divider
        v-if="getProtocalName(myData)"
        direction="vertical"
      ></el-divider>
      <span class="gray cs mr hr" @click="entrustProtocol(myData)">{{
        getProtocalName(myData)
      }}</span>
      <svg-icon
        v-if="
          myData.sfjj === 'Y' &&
          [12, 13, 14, 15, 16, 17, 25, 27, 28, 29].includes(myData.status)
        "
        icon-class="urgentSuccess"
        class="urgent mr"
      />
      <svg-icon
        v-if="
          myData.sfjj === 'Y' &&
          [11, 20, 21, 23, 24, 26].includes(myData.status)
        "
        icon-class="urgentReady"
        class="urgent mr"
      />
      <el-divider v-if="myData.lotno" direction="vertical"></el-divider>
      <el-tooltip
        class="item"
        effect="light"
        :content="myData.lotno"
        placement="right"
      >
        <span>{{ lotno }}</span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      busiType: order.busiType,
    };
  },
  computed: {
    //样本名称
    samplename() {
      if (this.myData?.samplename.length > 20) {
        return `${this.myData.samplename.substring(0, 20)}...`;
      }
      return this.myData.samplename;
    },
    //样本批号
    lotno() {
      if (this.myData?.lotno.length > 12) {
        return `${this.myData.lotno.substring(0, 12)}...`;
      }
      return this.myData.lotno;
    },
  },
  components: {},
  methods: {
    // 获取合同字段名
    getProtocalName(val) {
      const { status } = val;
      const protocolStatus = [12, 13, 14, 15, 16, 17, 24, 25, 26, 27, 28, 29]; //可以展示委托协议的状态
      if (protocolStatus.includes(status)) {
        return "委托协议";
      }
    },
    // 委托协议
    entrustProtocol(val) {
      const { contractUrl } = val;
      if (contractUrl) window.open(contractUrl);
    },
  },
};
</script>
<style lang="scss" scoped></style>
