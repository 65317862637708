import { afterSales, deleteBsgOtdOrder } from "@/api/order/index";
import { order } from "@/utils/dict";
import operationRemark from "@/views/bsg/components/operationRemark.vue";
import { copyBsgOtdOrder } from "@/api/order/index";
import review from "@/views/bsg/components/review.vue";
import urgent from "@/views/bsg/components/urgent.vue";
import afterSale from "@/views/bsg/components/afterSale.vue";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    //上传图片参数
    upload: {
      type: Object,
      default() {
        return {};
      },
    },
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    operationRemark,
    review,
    urgent,
    afterSale,
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    selected: {
      handler(newVal) {
        this.mySelected = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      myData: this.data,
      auditRemarkStatus: [11, 20, 21, 23], //	查看审核详情对应的状态列表
      auditRemarkDialog: false, //	审核详情弹窗
      urgentRemarkStatus: [24, 25, 26], //	查看加急审核详情对应的状态列表
      urgentRemarkDialog: false, //	加急审核详情弹窗
      afterSaleRemarkStatus: [16, 17, 28], //	查看售后申请审核详情对应的状态列表
      afterSaleRemarkDialog: false, //	售后申请审核详情弹窗
      vieRemarkStatus: [20], //	可以查看备注的状态列表
      rejectSubmit: [20, 23], //	驳回待提交阶段可以执行的操作
      resultConfirmed: [15, 28], //结果待确认阶段状态可以执行的操作
      dialogApplyAfterSale: false, //申请售后弹窗
      applyAfterSaleForm: {
        remark: "", //备注
        img: "", //上传图片
      }, //申请售后表单
      applyAfterSaleRule: {
        remark: [
          { required: true, message: "请填写备注", trigger: "blur" },
          {
            min: 5,
            max: 300,
            message: "长度在 5 到 300 个字符",
            trigger: "blur",
          },
        ],
      }, //申请售后校验规则
      //上传文件请求头
      uploadHeaders: {
        Authorization: this.$utils.storage.get("user").token,
      },
      fillTestInfoForm: {}, //订单检测数据
      fillDemoInfoForm: {}, //订单样本数据
      showCreateOrder: false, //是否展示修改订单弹窗
      rejectSubmitQuery: {
        type: "submitAgain",
        id: undefined,
      },
      remarkData: {}, //查看备注弹窗参数
      dialogRemark: false, //查看备注弹窗
      openBsgOtd: false, //查看bsgOtd详情弹窗
      detailData: null, //查看详情弹窗参数
      mySelected: this.selected, //当前选中项
      openBsgLtc: false, //查看bsgLtc详情弹窗
    };
  },
  computed: {
    status() {
      return this.myData.status;
    },
    myUpload() {
      return this.upload;
    },
    nullReport() {
      const allReportStatus = [
        ...this.rejectSubmit,
        ...this.resultConfirmed,
        ...this.vieRemarkStatus,
      ];
      if (allReportStatus.includes(this.status)) {
        return false;
      } else {
        return true;
      }
    },
    //评价按钮
    appraiseBtn() {
      if (this.myData.submitreviews === "Y") {
        return "查看评价";
      } else if (this.myData.submitreviews === "N") {
        return "服务评价";
      }
    },
  },
  methods: {
    // 重新提交成功回调
    rejectSubmitSuccess() {
      this.showCreateOrder = false;
      this.$emit("handleGetOrderLsit");
    },
    // 复制下单
    handleCopy() {
      if (this.mySelected.length > 1) {
        this.$message({
          type: "warning",
          message: "复制下单只能选择一条订单",
        });
      } else if (this.mySelected.length === 0) {
        this.$message({
          type: "warning",
          message: "请先选择一条订单以便复制下单",
        });
      } else {
        this.copy(this.mySelected[0]);
      }
    },
    // 展开复制弹窗
    copy({ vbillcode, busitype }) {
      if (busitype !== order.busiType.bsgOtd) {
        this.$message({
          type: "warning",
          message: "复制订单仅限于BSG-OTD业务类型",
        });
        return;
      }
      this.$messageBox
        .confirm("复制成功后生成的订单将显示在草稿箱中，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            orderNo: vbillcode,
          };
          copyBsgOtdOrder(params).then((res) => {
            this.$message({
              type: "success",
              message: "订单复制成功，请前往草稿箱确认订单资料再提交！",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
    // 点击评价
    handleAppraise(orderType) {
      if (orderType === "bsgOtd" && this.myData.submitreviews === "Y") {
        //评价类型：bsgOtd查看
        this.$emit("orderEvent", {
          data: [this.myData],
          type: "bsgOtdViewAppraise",
        });
      } else if (orderType === "bsgOtd" && this.myData.submitreviews === "N") {
        //评价类型：bsgOtd提交
        this.$emit("orderEvent", {
          data: [this.myData],
          type: "bsgOtdSubmitAppraise",
        });
      } else if (orderType === "bsgLtc" && this.myData.submitreviews === "Y") {
        //评价类型：bsgLtc查看
        this.$emit("orderEvent", {
          data: [this.myData],
          type: "bsgLtcViewAppraise",
        });
      } else if (orderType === "bsgLtc" && this.myData.submitreviews === "N") {
        //评价类型：bsgLtc提交
        this.$emit("orderEvent", {
          data: [this.myData],
          type: "bsgLtcSubmitAppraise",
        });
      }
    },
    // 获取订单备注流程当前阶段
    getOrderRemarkProcess(status) {
      const curStatus = order.remarkProcessList.find((item) =>
        item.value.includes(status)
      );
      return curStatus && curStatus.name;
    },
    // 查看订单备注信息
    viewRemark(row) {
      const newRow = JSON.parse(JSON.stringify(row));
      this.remarkData = newRow;
      this.dialogRemark = true;
    },
    // 删除订单
    handleDelete() {
      this.$messageBox
        .confirm("此操作将删除订单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          deleteBsgOtdOrder(this.myData.vbillcode).then((data) => {
            this.$message({
              type: "success",
              message: "删除订单已成功",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除订单",
          });
        });
    },
    // 加载申请售后
    handleApplyAfterSale() {
      this.$refs.applyAfterSale.validate((valid) => {
        if (valid) {
          this.applyAfterSale();
        }
      });
    },
    // 申请售后
    applyAfterSale() {
      const params = {
        url: this.applyAfterSaleForm.img, //附件图片
        orderNo: Number(this.myData.vbillcode), //订单id
        reason: this.applyAfterSaleForm.remark, //备注
        businessType: this.$utils.map.getUserBstpNo(),
      };
      afterSales(params).then((res) => {
        this.dialogApplyAfterSale = false;
        this.$emit("handleGetOrderLsit");
        this.$message({
          type: "success",
          message: "申请售后提交成功",
        });
      });
    },
    // 图片上传成功回调
    updateFile(file, fileList) {
      const imgUrls = [];
      fileList.forEach((item) => {
        //fileList里包含了返显数据，需要判断
        if (item.response && item.response.data) {
          imgUrls.push(item.response.data);
        }
      });
      this.applyAfterSaleForm.img = imgUrls.join(",");
    },
    // 获取提交草稿或订单的参数
    getParams() {
      const dynamicDataList = JSON.parse(
        JSON.stringify(this.fillTestInfoForm.dynamicDataList)
      );
      dynamicDataList.forEach((item) => {
        delete item.fileList;
      });
      const params = {
        batchNo: this.fillDemoInfoForm.demoNo, //样品批号
        concentration: this.fillDemoInfoForm.concentration, //浓度；单位：mg/ml
        count: this.fillDemoInfoForm.demoAmount, //样品数量
        dynamicDataList, //检测信息动态字段
        expiryDate: this.fillDemoInfoForm.endDate, //有效期
        gelType:
          this.fillDemoInfoForm.demoState === "凝胶"
            ? this.fillDemoInfoForm.gel
            : "", //凝胶类型； 1：1D银染。2：1D考染。3：2D银染。4：2D考染
        isKeepingSample: this.fillTestInfoForm.hasRemain, //是否留样；1：不留样。2：留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）
        orderId: this.orderId ? this.orderId : undefined, //订单ID
        productId: this.fillTestInfoForm.product
          ? this.fillTestInfoForm.product
          : undefined, //产品id
        purity: this.fillDemoInfoForm.purity, //纯度
        pvdf:
          this.fillDemoInfoForm.demoState === "PVDF膜"
            ? this.fillDemoInfoForm.pvdf
            : "", //PVDF膜；1：考染。2：丽春红
        remark: this.fillTestInfoForm.remark, //备注
        reportLanguage: this.fillTestInfoForm.reportLanguage, //报告语言；1：中文。
        reportMedium: this.fillTestInfoForm.reportMedium, //报告介质；1：电子版。2：纸质版。3：两者皆需要。
        reportVersion: this.fillTestInfoForm.reportVersion, //报告版本；1：CMA报告+APT报告。2：中科新生命版 (APT版)。
        residualSampleDispose: this.fillTestInfoForm.handleRemain, //余样处理；1：不退样。2：退样
        sampleName: this.fillDemoInfoForm.demoName, //样品名称
        samplePackage: this.fillDemoInfoForm.demoPack, //样品包装；1：离心管。2：玻璃瓶。3：塑料容器。4：其他。
        sampleSendMethod: this.fillTestInfoForm.postType, //送样方式；1：物流送样。2：客户送样。3：委托抽样（需另外支付费用）。
        sampleSource: this.fillDemoInfoForm.demoOrigin, //样品来源；1：生产。2：购买（请提供原包装信息）。3：其他（备注里请明确）。
        sampleType: this.fillDemoInfoForm.demoType, //样品类型；1：小肽类。2：重组蛋白。3：单抗类。4：其他类。
        specs: this.fillDemoInfoForm.demoStandard, //样品规格；1：原料药。2：化合物。3：成品药。4：中间体。5：其他。
        state: this.fillDemoInfoForm.demoState, //样品状态；1：固体。2：液体。3：凝胶。4：PVDF膜。
        storageConditions: this.fillDemoInfoForm.saveCondition, //贮存条件；1：室温。2：2-8℃。3：-15至-25℃。4：-65至-85℃
        testType: this.fillTestInfoForm.testType, //检测类别；1：委托。2：出口。3：复核。4：其他。
        trait: this.fillDemoInfoForm.demoShape, //样品性状；1：无色澄清液体。2：淡黄色澄清液体。3：白色固体粉末。4：类白色固体粉末。5：其他。
        volume:
          this.fillDemoInfoForm.demoState === "液体"
            ? this.fillDemoInfoForm.volume
            : undefined, //体积；单位：ml
        weight:
          this.fillDemoInfoForm.demoState === "固体"
            ? this.fillDemoInfoForm.weight
            : undefined, //重量；单位：mg
      };
      return params;
    },
    // 驳回再提交
    handeRejectSubmit() {
      this.rejectSubmitQuery.id = this.myData.vbillcode;
      this.showCreateOrder = true;
    },
  },
};
