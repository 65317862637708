<template>
  <!-- 服务评价 -->
  <el-drawer
    title="服务评价"
    :visible.sync="myShow"
    direction="rtl"
    :wrapperClosable="false"
    size="70%"
    class="elDrawer"
    @close="close"
    append-to-body
  >
    <div class="content_wrap">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="elForm"
        label-position="top"
      >
        <el-form-item
          label="1、请您对本次的检验服务作出评价（说明：0 ~ 10 分）"
          prop="serviceScore"
        >
          <div class="pleased_header">
            <div class="item">
              <span class="desc">非常不满意</span>
              <span class="img el-icon-my-bumanyi"></span>
            </div>
            <div class="item">
              <span class="desc">非常满意</span>
              <span class="img el-icon-my-manyi"></span>
            </div>
          </div>
          <el-radio-group v-model="form.serviceScore">
            <el-radio
              :label="item.label"
              v-for="(item, index) of order.serviceScore"
              :key="index"
              :disabled="disabled"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="2、您觉得从中科新生命获得所需的帮助是否简单方便？（说明：1 ~ 7 分）"
          prop="experienceScore"
        >
          <div class="pleased_header">
            <div class="item">
              <span class="desc">极为困难</span>
              <span class="img el-icon-my-bumanyi"></span>
            </div>
            <div class="item">
              <span class="desc">极为简单</span>
              <span class="img el-icon-my-manyi"></span>
            </div>
          </div>
          <el-radio-group v-model="form.experienceScore">
            <el-radio
              :label="item.label"
              v-for="(item, index) of order.experienceScore"
              :key="index"
              :disabled="disabled"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="3、您是否愿意向朋友或者同事推荐中科新生命？（说明：0 ~ 10 分）"
          prop="recommendedScore"
        >
          <div class="pleased_header">
            <div class="item">
              <span class="desc">完全不推荐</span>
              <span class="img el-icon-my-bumanyi"></span>
            </div>
            <div class="item">
              <span class="desc">非常推荐</span>
              <span class="img el-icon-my-manyi"></span>
            </div>
          </div>
          <el-radio-group v-model="form.recommendedScore">
            <el-radio
              :label="item.label"
              v-for="(item, index) of order.recommendedScore"
              :key="index"
              :disabled="disabled"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="4、您对中科新生命检验服务的意见或建议（非必填）"
          prop="suggestion"
        >
          <el-input
            type="textarea"
            v-model="form.suggestion"
            placeholder="最多不能超过200个汉字"
            maxlength="200"
            style="width: 100%"
            :rows="5"
            class="remark"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <slot name="footer">
      <div class="footer">
				<el-button @click.native="close">取 消</el-button>
				<el-button type="primary" @click.native="confirm" v-if="showConfirm"
					>提 交</el-button
				>
				<span style="color: red; font-weight: 600;margin-left: 24px;">提醒：如需获取签字盖章版本的纸质版报告，请前往订单操作【申请打印】</span>
			</div>
    </slot>
  </el-drawer>
</template>

<script>
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
      this.init();
    },
  },
  data() {
    return {
      myData: this.data, // 接收父组件参数
      rules: {
        serviceScore: [{ required: true, message: "请选择", trigger: "blur" }],
        experienceScore: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        recommendedScore: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      //表单数据
      form: {
        experienceScore: "1", //体验评分
        orderNo: [], //订单号
        recommendedScore: "10", //推荐评分
        serviceScore: "10", //服务评分
        suggestion: "", //建议
      },
      order, //字典
      disabled: false, //表单是否可填
      rateOrder: null, //服务评价API
      queryRate: null, //查询评价API
    };
  },
  computed: {
    //是否展示弹窗
    myShow: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    //展示确认按钮
    showConfirm() {
      const types = [
        "bsgOtdSubmitAppraise",
        "bsgLtcSubmitAppraise",
        "bsgOtdBatchSubmitAppraise",
        "bsgLtcBatchSubmitAppraise",
      ];
      return types.includes(this.myData.type);
    },
  },
  methods: {
    // 按条件加载接口
    async getApi() {
      let api;
      if (this.myData.type.includes("bsgLtc")) {
        //bsgLtc接口
        api = await import("@/api/order/bsg/ltc");
      } else if (this.myData.type.includes("bsgOtd")) {
        //bsgOtd接口
        api = await import("@/api/order/bsg/otd");
      }
      this.rateOrder = api.rateOrder;
      this.queryRate = api.queryRate;
    },
    // 初始化已存在的数据
    async init() {
      await this.getApi();
      if (
        this.myData.type === "bsgOtdViewAppraise" ||
        this.myData.type === "bsgLtcViewAppraise"
      ) {
        //初始化查看评价
        this.getAppraise();
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    //初始化查看评价
    getAppraise() {
      const params = {
        orderNo: this.myData.data[0].vbillcode,
      };
      this.queryRate(params).then((res) => {
        this.form.suggestion = res.evalurecom;
        this.form.serviceScore = res.servicerating;
        this.form.experienceScore = res.experiencerating;
        this.form.recommendedScore = res.recommendedrating;
      });
    },
    async validate() {
      const result = await this.$refs.form.validate();
      return result;
    },
    async confirm() {
      const result = await this.validate();
      if (!result) return;
      this.form.orderNo = [];
      this.myData.data.forEach((item) => {
        this.form.orderNo.push(item.vbillcode);
      });
      this.rateOrder(this.form).then(() => {
        this.$emit("success");
        this.$message({
          type: "success",
          message: "评价提交成功",
        });
        this.close();
      });
    },
    close() {
      this.myShow = false;
      this.form = {
        experienceScore: "1", //体验评分
        orderNo: [], //订单号
        recommendedScore: "10", //推荐评分
        serviceScore: "10", //服务评分
        suggestion: "", //建议
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elDrawer {
  .content_wrap {
    padding: 10px 22px;
    text-align: left;
    box-sizing: border-box;
    min-height: calc(100% - 102px);
    .elForm {
      width: 80%;
      .pleased_header {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          align-items: center;
          .img {
            margin-left: 10px;
            font-size: 32px;
          }
        }
      }
    }
  }
  ::v-deep .el-drawer__header > :first-child {
    text-align: left;
  }
  .footer {
    display: flex;
    width: 100%;
    padding: 10px 22px 30px 22px;
    border-top: 1px solid $theme_bd_color;
  }
}
</style>
