<!-- 订单详情  -->
<template>
  <el-drawer
    title="订单详情"
    :visible.sync="myOpen"
    size="75%"
    class="elDrawer"
    direction="rtl"
    v-if="myData"
    @close="$emit('input', false)"
  >
    <div class="content-wrap">
      <el-descriptions title="订单信息" class="content-item">
        <el-descriptions-item label="订单编号">{{
          myData.order.bpOrderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="订单金额">{{
          myData.order.contractPrice | fmtContractPrice
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="检测信息">
        <el-descriptions-item label="所属产品">{{
          myData.proProduct.name
        }}</el-descriptions-item>
        <el-descriptions-item
          v-for="(item, index) of myData.dynamicDataList"
          :key="index"
          :label="getItemName(item.name)"
        >
          <span
            v-if="
              item.fieldType === 'text' ||
              item.fieldType === 'textarea' ||
              item.fieldType === 'select'
            "
            >{{ item.value }}</span
          >
          <template v-if="item.fieldType === 'img'">
            <div
              v-for="(item2, index) of item.fileList"
              :key="index"
              class="tsImg_wrap"
            >
              <!-- <img :src="item2.url" alt="" class="tsImg" @click="handlePictureDialog(item2.url)"/> -->
              <!-- <i
                class="el-icon-zoom-in"
                @click="handlePictureDialog(item2.url)"
              ></i> -->
              <el-image
                style="width: 100px; height: 100px"
                :src="item2.url"
                :preview-src-list="[item2.url]"
              >
              </el-image>
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="报告版本">{{
          myData.ordDetection.reportVersion
        }}</el-descriptions-item>
        <el-descriptions-item label="报告介质">{{
          myData.ordDetection.reportMedium
        }}</el-descriptions-item>
        <el-descriptions-item label="报告语言">{{
          myData.ordDetection.reportLanguage
        }}</el-descriptions-item>
        <el-descriptions-item label="检测类别">{{
          myData.ordDetection.testType
        }}</el-descriptions-item>
        <el-descriptions-item label="送样方式">{{
          myData.ordDetection.sampleSendMethod
        }}</el-descriptions-item>
        <el-descriptions-item label="余样处理">{{
          myData.ordDetection.residualSampleDispose
        }}</el-descriptions-item>
        <el-descriptions-item label="是否留样">{{
          myData.ordDetection.isKeepingSample
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="content-item">
        <el-descriptions-item label="委托备注">{{
          myData.ordDetection.remark
        }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="样本信息">
        <el-descriptions-item label="样本类型">{{
          myData.ordSample.sampleType
        }}</el-descriptions-item>
        <el-descriptions-item label="样本来源">{{
          myData.ordSample.sampleSource
        }}</el-descriptions-item>
        <el-descriptions-item label="样本名称">{{
          myData.ordSample.sampleName
        }}</el-descriptions-item>
        <el-descriptions-item label="样本批号">{{
          myData.ordSample.batchNo
        }}</el-descriptions-item>
        <el-descriptions-item label="样本数量">{{
          myData.ordSample.count
        }}</el-descriptions-item>
        <el-descriptions-item label="样本性状">{{
          myData.ordSample.trait
        }}</el-descriptions-item>
        <el-descriptions-item label="纯度(%)">{{
          myData.ordSample.purity
        }}</el-descriptions-item>
        <el-descriptions-item label="截止有效期">{{
          $utils.map.parseTime(myData.ordSample.expiryDate, "{y}-{m}-{d}")
        }}</el-descriptions-item>
        <el-descriptions-item label="样本规格">{{
          myData.ordSample.specs
        }}</el-descriptions-item>
        <el-descriptions-item label="贮存条件">{{
          myData.ordSample.storageConditions
        }}</el-descriptions-item>
        <el-descriptions-item label="样本包装">{{
          myData.ordSample.samplePackage
        }}</el-descriptions-item>
        <el-descriptions-item label="样本状态">{{
          myData.ordSample.state
        }}</el-descriptions-item>
        <el-descriptions-item
          label="重量(mg)"
          v-if="myData.ordSample.state === '固体'"
          >{{ myData.ordSample.weight }}</el-descriptions-item
        >
        <el-descriptions-item
          label="浓度(mg/ml)"
          v-if="myData.ordSample.state === '液体'"
          >{{ myData.ordSample.concentration }}</el-descriptions-item
        >
        <el-descriptions-item
          label="体积(ml)"
          v-if="myData.ordSample.state === '液体'"
          >{{ myData.ordSample.volume }}</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions class="content-item">
        <el-descriptions-item label="实验备注"
          >{{ myData.ordDetection.experimentalRequirements }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        title="委托协议寄送信息"
        class="content-item"
        v-if="user.userType === 0"
      >
        <el-descriptions-item label="收件人">{{
          myData.order.entrustsendpsn
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          myData.order.entrustsendtel
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱地址">{{
          myData.order.entrustsendemail
        }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{
          myData.order.entrustsendaddress
        }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        title="报告寄送信息"
        class="content-item"
        v-if="user.userType === 0"
      >
        <el-descriptions-item label="收件人">{{
          myData.order.reportsendpsn
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          myData.order.reportsendtel
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱地址">{{
          myData.order.reportsendemail
        }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{
          myData.order.reportsendaddress
        }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="加急需求">
        <el-descriptions-item label="是否加急">{{
          isUrgent(myData.order.urgent)
        }}</el-descriptions-item>
        <el-descriptions-item
          label="期望完成"
          v-if="myData.order.urgent === 'Y'"
          >{{
            $utils.map.parseTime(myData.order.expectfintime, "{y}-{m}-{d}")
          }}</el-descriptions-item
        >
      </el-descriptions>
    </div>
    <!-- 产品信息图片弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="tsImg_dialog"
      append-to-body
      width="80%"
      height="80%"
      center
    >
      <img :src="dialogUrl" class="tsImg" alt="" />
    </el-dialog>
  </el-drawer>
</template>

<script>
import { getProductDetection } from "@/api/product/index";
import { getBsgOtdOrderDetail } from "@/api/order/index";
import { order } from "@/utils/dict";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myData: null,
      myOpen: this.value,
      dialogVisible: false, //图片弹窗是否显示
      dialogUrl: "", //弹窗图片地址
      user: this.$utils.storage.get("user"), // 用户数据
    };
  },
  watch: {
    value(newVal) {
      this.myOpen = newVal;
    },
  },
  methods: {
    // 加载图片弹窗
    handlePictureDialog(url) {
      this.dialogUrl = url;
      this.dialogVisible = true;
    },
    // 获取数据名称
    getItemName(name) {
      if (name === "分子量") {
        return "分子量(Da)";
      } else {
        return name;
      }
    },
    // 文件列表格式处理
    transFileList(val) {
      const list = val.split(",");
      const newList = [];
      list.forEach((item) => {
        newList.push({
          url: item,
        });
      });
      return newList;
    },
    // 是否加急
    isUrgent(val) {
      if (val === "Y") {
        return "加急";
      } else if (val === "N") {
        return "不加急";
      } else {
        return "未知";
      }
    },
    // 获取产品动态检测信息字段
    async handleGetProductDetection() {
      const { order } = this.myData;
      if (order && order.productId) {
        const params = {
          productId: order.productId,
        };
        await getProductDetection(params).then((res) => {
          //产品动态检测信息字段
          this.myData.detectionFields = res.detectionFields;

          const dynamicDataList = [];
          const preDynamicDataList = [];
          this.myData.dynamicDataList.forEach((item) => {
            preDynamicDataList.push(item.fieldId);
          });
          this.myData.detectionFields.forEach((item, index) => {
            //更新动态检测字段列表顺序，使其与默认的detectionFields一致。
            if (preDynamicDataList.includes(item.id)) {
              this.myData.dynamicDataList.forEach((item2) => {
                const obj = {
                  ...item2,
                };
                if (item.id === item2.fieldId) {
                  if (item.fieldType === "img") {
                    obj.fileList = this.transFileList(item2.value);
                  }
                  obj.fieldType = item.fieldType;
                  dynamicDataList.push(obj);
                }
              });
            } else {
              dynamicDataList.push({
                fieldId: item.id,
                name: item.fieldName,
                value: "",
                fileList: [],
              });
            }
          });

          this.myData.dynamicDataList.splice(
            0,
            this.myData.dynamicDataList.length,
            ...dynamicDataList
          ); //更新动态检测字段列表顺序，使其与默认的detectionFields一致。
        });
      }
    },
    // 获取订单产品详情
    async handleGetOrderDetail(id) {
      this.myData = await getBsgOtdOrderDetail(id);
      await this.handleGetProductDetection();
    },
  },
  filters: {
    fmtContractPrice(val) {
      return `${val} 元`;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrap {
  margin: 10px 22px;
  .content-item {
    margin-bottom: 32px;
  }
  .tsImg_wrap {
    position: relative;
    display: inline-block;
    .tsImg {
      display: flex;
      width: 100px;
      height: 100px;
      border-radius: 4px;
      box-sizing: border-box;
    }
    .el-icon-zoom-in {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  ::v-deep .el-descriptions-item__cell {
    padding-right: 20px;
  }
}
.elDrawer ::v-deep .el-drawer__header > :first-child {
  text-align: left;
}

.tsImg_dialog ::v-deep .tsImg {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
</style>
